<template>
  <div class="maintenance">
    <Header
      :show-breadcrumb="false"
      :show-cart="false"
    />
    <div class="maintenance__content">
      <Typography
        variant="flow-text-title"
        class="maintenance__heading"
        v-html="maintenanceHeading"
      />
      <Typography
        variant="subheading"
        class="maintenance__subheading"
        v-html="maintenanceDescription"
      />
    </div>
    <Footer
      :has-meta-links="false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Typography from '@/components/typography.vue';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

export default {
  components: {
    Typography,
    Header,
    Footer,
  },
  data() {
    return {
      stageHeading: 'Vielen Dank für Ihr Interesse',
    };
  },
  computed: {
    ...mapGetters({
      maintenanceDowntime: 'downtimes/maintenanceDowntime',
    }),
    maintenanceHeading() {
      const defaultHeading = 'Aktuell arbeiten wir an der Umstellung der Mehrwertsteuer in unserem Portal.';
      return this.maintenanceDowntime?.maintenanceHeading || defaultHeading;
    },
    maintenanceDescription() {
      const defaultDescription = 'Leider ist diese Seite im Moment nicht erreichbar. Bitte versuchen Sie es später noch einmal.<br/> Wir bitten um Ihr Verständnis.';
      return this.maintenanceDowntime?.maintenanceDescription || defaultDescription;
    },
    isIndexed() {
      return false;
    },
    linkRel() {
      return 'nofollow';
    },
  },
};
</script>

<style scoped lang="scss">
.maintenance {
  background-image: url("../assets/images/error-background.png");
  background-position: 50% top;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1080px;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  // image-gallery parts have 20..
  // use case screensaver -> click on highlight
  z-index: 30;

  &__content {
    padding: 60px 0 0;
  }

  &__heading {
    margin-left: 26px;
    padding-right: 120px;
    grid-column: 1/-1;
  }

  &__subheading {
    font-size: 30px;
    margin-top: 12px;
    margin-left: 26px;
    padding-right: 120px;
  }

  .maintenance__hidden-info {
    display: inline-block;
    width: 200px;
    height: 100px;
    background-color: #fff;
    overflow: hidden;
    font-size: 20px;
    color: #888;
  }
}

</style>
