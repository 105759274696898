import axios from 'axios';
import { captureException, captureMessage } from './sentry';

// See: https://wiki.i22.de/display/TDSMARTHOME/Shop+Api+Calls
function endpoints() {
  const backendApiUrl = '/api/shop';
  return {
    order: `${backendApiUrl}/order`,
    transit: `${backendApiUrl}/orders/virtual_shelf/transit_to_tool`,
    prepareTransit: `${backendApiUrl}/orders/virtual_shelf/prepare_dein_design_handover`,
    items: `${backendApiUrl}/items`,
    item: (id) => `${backendApiUrl}/items/${Number(id)}`,
    paypalVPs: `${backendApiUrl}/paypal_vps`,
    downtimes: `${backendApiUrl}/downtimes`,
    login: `${backendApiUrl}/virtual_shelf_login`,
  };
}

const interceptErrors = (err) => {
  const { config, response } = err;
  const extra = {
    responseData: response && JSON.stringify(response.data),
    responseStatus: response && response.status,
    config: config && JSON.stringify(config),
  };

  try {
    if (response?.status === 422 && response?.data?.error === 'No valid login' && !err.request?.responseURL?.includes('/api/shop/virtual_shelf_login')) {
      const now = +new Date();
      const reloadTime = localStorage.getItem('reload-time');
      // reload only every 60 seconds.
      const vp = window.localStorage.getItem('vp');
      if (vp && (!reloadTime || reloadTime < now - 1000 * 60)) {
        captureMessage('Got a 422 from BE and try to re-login. Reloading VR.', {
          tag: {
            vp,
          },
        });
        localStorage.setItem('reload-time', now);
        window.location.reload();
      }
    }
  } catch (e) { captureException(e); }

  captureException(err, { extra });
  throw err;
};

const apiCalls = {
  downtimes() {
    return axios.get(endpoints().downtimes).catch(interceptErrors);
  },
  getCurrentCart() {
    return axios.get(endpoints().order).catch(interceptErrors);
  },
  transferCart() {
    return axios.patch(endpoints().transit, {}).catch(interceptErrors);
  },
  prepareTransit({ quantity, articleNumber }) {
    return axios.post(
      endpoints().prepareTransit,
      { item: { quantity, articleNumber } },
    ).catch(interceptErrors);
  },
  deleteCart() {
    return axios.delete(endpoints().order).catch(interceptErrors);
  },
  addItemToCart({ quantity, articleNumber }) {
    return axios.post(
      endpoints().items,
      { item: { quantity, articleNumber } },
    ).catch(interceptErrors);
  },
  updateItemInCart({ id, quantity }) {
    return axios.patch(endpoints().item(id), { quantity }).catch(interceptErrors);
  },
  removeItemFromCart({ id }) {
    return axios.delete(endpoints().item(id)).catch(interceptErrors);
  },
  paypalVPs() {
    return axios.get(endpoints().paypalVPs).catch(interceptErrors);
  },
  login(vp) {
    return axios.post(endpoints().login, { vp });
  },
};

// eslint-disable-next-line no-restricted-exports
export { apiCalls as default, endpoints };
