<template>
  <div class="search-page">
    <Header
      :show-cart="false"
      :show-breadcrumb="false"
      :show-cancel="true"
    />
    <div class="search-page__intro">
      <Typography
        variant="heading"
      >
        Menü und Suche
      </Typography>
      <SearchInput
        @focus="searchInputFocused"
        @change="searchInputChanged"
      />
    </div>
    <div class="search-page__content">
      <CategoryHierarchy
        class="search-page__categories"
      />
      <div
        v-if="searchElementsVisible"
        class="search-page__results"
      >
        <SearchSuggestions
          :suggestions="searchSuggestions"
        />
        <VueTouchKeyboard
          :input="searchInput"
          :layout="simplifiedLayout"
          :accept="startSearch"
          :visible="true"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import VueTouchKeyboardBundle from 'vue-touch-keyboard';
import { MESSAGES } from '@/store/loader';
import fetchSuggestions from '@/lib/goliath/suggestions';
import highlightSubterms from '@/lib/subterm-highlighter';
import Typography from '@/components/typography.vue';
import simplifiedLayout from '@/assets/js/vue-touch-keyboard.simplified.json';
import CategoryHierarchy from '@/components/category-hierarchy.vue';
import SearchInput from '@/components/search-input.vue';
import SearchSuggestions from '@/components/search-suggestions.vue';
import Footer from '@/components/footer.vue';
import Header from '@/components/header.vue';

const VueTouchKeyboard = VueTouchKeyboardBundle.component;

function generateHighlighter(term) {
  const subterms = term.trim().split(' ');
  const subtermHighlighter = highlightSubterms(subterms, 'b');
  return function reduceSuggestion({ product }) {
    return {
      slug: `/offer/${product.slug}`,
      html: subtermHighlighter(product.family.name),
    };
  };
}

export default {
  name: 'HelpPage',
  components: {
    CategoryHierarchy,
    SearchInput,
    SearchSuggestions,
    Footer,
    Header,
    Typography,
    VueTouchKeyboard,
  },
  data() {
    return {
      simplifiedLayout,
      searchElementsVisible: false,
      searchInput: undefined,
      searchTerm: '',
    };
  },
  computed: {
    searchSuggestions() {
      if (!this.rawSuggestions) return [];
      const highlighter = generateHighlighter(this.searchTerm);
      return this.rawSuggestions.data.offers.entries.map(highlighter);
    },
  },
  methods: {
    searchInputFocused(event) {
      if (this.searchElementsVisible) return;
      this.searchElementsVisible = true;
      this.searchInput = event.target;
    },
    startSearch() {
      const term = this.searchInput.value;
      if (term === 'ZEIGE DIE VP') {
        this.$router.push({ name: 'error' });
        return;
      }
      this.searchElementsVisible = false;
      if (term.match(/^ *$/)) return;
      this.$store.commit('loader/PUSH_MESSAGE', MESSAGES.LOADING_PRODUCTS);
      this.$router.push({ name: 'search-offers', query: { term } });
    },
    searchInputChanged(newValue) {
      // FIXME sanitize, goliathify?
      this.searchTerm = newValue.replace(/\s+/g, ' ');
    },
  },
  asyncComputed: {
    async rawSuggestions() {
      if (!this.searchTerm) return undefined;
      return fetchSuggestions({ search: this.searchTerm });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vue-touch-keyboard.scss";

.search-page {
  &__intro {
    padding: 0 30px;
  }

  &__content {
    position: relative;
    width: calc(100% - 60px);
    height: 1200px;
    margin: 0 auto;
  }

  &__categories,
  &__results {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
