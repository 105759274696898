<template>
  <div class="category-subcategories-page">
    <Header />
    <StaticShelf :name="category" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import StaticShelf from '@/components/static-shelf.vue';
import actions from '@/store/actions';

export default {
  name: 'CategorySubcategoriesPage',
  components: {
    Header,
    Footer,
    StaticShelf,
  },
  computed: {
    ...mapState({ categories: (state) => state.shelves.categories }),
    category() {
      return this.$route.params.topcategory;
    },
    breadcrumbItems() {
      const items = [{ label: 'Home', path: '/start' }];
      if (this.category && this.categories) {
        const item = Object.values(this.categories).find((entry) => entry.slug === this.category);
        if (item) {
          items.push({ label: item.label, path: null });
        }
      }

      return items;
    },
  },
  watch: {
    breadcrumbItems: {
      handler(value) {
        this.$store.dispatch(actions.UPDATE_BREADCRUMB, value);
      },
      immediate: true,
    },
  },
};
</script>
