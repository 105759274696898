<template>
  <div class="breadcrumb">
    <button
      v-for="(item,index) in navItems"
      :key="index"
      class="breadcrumb__link"
      @click="goToPath(item.path)"
    >
      <span
        class="breadcrumb__item"
        :class="`breadcrumb__item--${item.classSuffix}`"
        v-html="item.label"
      />
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Breadcrumb',
  data() {
    return {
      maxItems: 4,
    };
  },
  computed: {
    ...mapState({ items: (state) => state.breadcrumb.entries }),
    navItems() {
      const filledSegments = this.items.map((item) => ({
        ...item,
        classSuffix: 'filled',
      }));
      filledSegments[filledSegments.length - 1].classSuffix = 'active';
      if (filledSegments.length === this.maxItems) return filledSegments;
      const emptySegmentCount = this.maxItems - this.items.length;
      const emptySegments = new Array(emptySegmentCount).fill({
        classSuffix: 'empty',
      });
      return [...filledSegments, ...emptySegments];
    },
  },
  methods: {
    goToPath(path) {
      if (!path || this.$route.path === path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.breadcrumb {
  display: flex;
  justify-content: center;
  width: 100%;

  &__link {
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    height: 120px;
    outline: inherit;
    padding: 0 0 44px;
    text-align: left;
    width: 175px;
    margin: 0 10px;
  }

  &__item {
    @include font-size(24px, 27px);
    border-bottom: 2px solid #a4a4a4;
    display: table-cell;
    font-weight: 400;
    height: 76px;
    letter-spacing: 0.5px;
    padding: 10px 0;
    vertical-align: bottom;
    width: 175px;

    &--filled {
      border-bottom: 2px solid #262626;
    }

    &--active {
      border-bottom: 4px solid #e20074;
      color: #e20074;
      font-weight: 700;
    }
  }
}
</style>
