<template>
  <div
    v-show="options.length"
    ref="appCompatibilityFilter"
    class="app-compatibility-filter"
  >
    <Typography
      variant="title-bold"
      class="app-compatibility-filter__heading"
    >
      Kompatibel mit App-Version
      <LinkButton
        ref="appCompatibilityFilterModalLink"
        tag="button"
        transparent
        class="app-compatibility-filter__link"
        aria-label="Mehr erfahren"
        tealium-suffix="app-compatibility-filter"
        tealium-name="Mehr erfahren"
        type="button"
        @click="$refs.appCompatibilityFilterModal.open()"
      >
        *
      </LinkButton>
    </Typography>
    <div
      ref="appCompatibilityFilterItemGroup"
      class="app-compatibility-filter__item-group"
    >
      <SelectableItem
        v-if="allSelector"
        ref="selectableItemAll"
        class="app-compatibility-filter__selectable-item"
        :value="allSelected"
        @input="onInputAllSelector"
      >
        <span class="app-compatibility-filter__label">
          Alle
        </span>
      </SelectableItem>
      <SelectableItem
        v-for="(entry, index) in options"
        ref="selectableItem"
        :key="`selectable-item-appCompatibility-${index}`"
        :value="isSelected(entry.tag.slug)"
        class="app-compatibility-filter__selectable-item"
        @input="onInput($event, entry.tag.slug)"
      >
        <span
          class="app-compatibility-filter__label"
          :aria-describedby="entry.label.includes('*') && `${uid}ItemFootnote`"
        >
          {{ entry.label }}
        </span>
      </SelectableItem>
    </div>
    <Paragraph
      :id="`${uid}ItemFootnote`"
      class="app-compatibility-filter__selectable-item-footnote"
      is-size-big
    >
      * kostenlos
    </Paragraph>
    <ModalPortal
      ref="appCompatibilityFilterModal"
      class="app-compatibility-filter__modal"
      @close="$refs.appCompatibilityFilterModalLink.$el.focus()"
    >
      <Paragraph class="app-compatibility-filter__modal-text">
        Für die Steuerung von smarten Geräten stehen derzeit verschiedene App-Versionen zur
        Verfügung. Bei Aktivierung dieses Filters werden nur Geräte angezeigt, die mit der
        jeweiligen App-Version gesteuert werden können.
      </Paragraph>
    </ModalPortal>
  </div>
</template>

<script>
import { isEmpty, pull, uniqueId } from 'lodash';
import { gsap } from 'gsap';
import { LinkButton, Paragraph } from '@i22-td-smarthome/component-library';
import SelectableItem from '@/components/selectable-item.vue';
import DraggableMixin from '@/mixins/draggable-mixin';
import ModalPortal from '@/components/modal-portal.vue';
import Typography from '@/components/typography.vue';

export default {
  name: 'AppCompatibilityFilter',
  components: {
    LinkButton,
    Paragraph,
    ModalPortal,
    SelectableItem,
    Typography,
  },
  mixins: [
    DraggableMixin('appCompatibilityFilterItemGroup'),
  ],
  props: {
    allSelector: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uid: uniqueId('appCompatibilityFilter'),
      selectedOptions: [...this.value],
    };
  },
  computed: {
    allSelected() {
      return !this.selectedOptions.length;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedOptions = [...value];
      },
    },
    active: {
      handler(becomesActive) {
        if (!becomesActive) return;
        if (this.initialAnimationDone) return;
        this.initIntroSlide();
        this.initialAnimationDone = true;
      },
    },
  },
  methods: {
    isSelected(slug) {
      return this.selectedOptions.includes(slug);
    },
    emitInput(selectedOptions) {
      this.$emit('input', selectedOptions);
      this.$emit('change');
      if (isEmpty(selectedOptions)) this.$emit('reset');
    },
    onInputAllSelector() {
      this.emitInput([]);
    },
    onInput(val, slug) {
      const selectedOptions = [...this.selectedOptions];
      if (selectedOptions.includes(slug)) {
        pull(selectedOptions, slug);
      } else {
        selectedOptions.push(slug);
      }
      this.emitInput(selectedOptions);
    },
    getItemGroupWidth() {
      return this.$refs.appCompatibilityFilterItemGroup
        .getBoundingClientRect()
        .left;
    },
    animateToX(xVal) {
      const tl = gsap.timeline();
      tl.to(this.$refs.appCompatibilityFilterItemGroup, {
        x: xVal,
        duration: 2,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-compatibility-filter {
  &__heading {
    display: flex;
    padding: 72px 30px 24px 30px;
  }

  &__link {
    align-self: flex-start;
    margin-left: 8px;
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 24px;
  }

  &__item-group {
    display: flex;
    padding: 0 30px;
  }

  &__selectable-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  &__selectable-item-footnote {
    padding: 32px 30px 0;
  }

  &__label {
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    display: block;
  }

  &__modal-text {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
