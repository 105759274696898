<template>
  <div class="header">
    <div class="header__back">
      <slot name="back">
        <BackButton
          v-if="showBackButton"
          class="header__back-button"
          :path="backButtonPath"
        />
      </slot>
    </div>
    <div class="header__breadcrumb">
      <Breadcrumb v-if="showBreadcrumb" />
    </div>
    <div class="header__aux">
      <NavigationCartButton
        v-if="!showCancel && showCart"
        :is-active="isCartActive"
      />
      <BackButton
        v-if="showCancel"
        variant="cancel"
        class="header__cancel-button"
      />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/back-button.vue';
import Breadcrumb from '@/components/breadcrumb.vue';
import NavigationCartButton from '@/components/navigation-cart-button.vue';

export default {
  name: 'Header',
  components: { BackButton, Breadcrumb, NavigationCartButton },
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showBreadcrumb: {
      type: Boolean,
      default: true,
    },
    showCart: {
      type: Boolean,
      default: true,
    },
    isCartActive: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    backButtonPath: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.header {
  align-items: flex-end;
  display: flex;
  height: 120px;
  width: 100%;

  &__back {
    width: 160px;
  }

  &__back-button {
    height: 120px;
    padding-top: 30px;
  }

  &__breadcrumb {
    flex: 1 0 auto;
  }

  &__aux {
    display: flex;
    justify-content: flex-end;
    width: 160px;
  }
}
</style>
