<template>
  <div
    v-if="hasEnergyLabel"
    class="energy-label-container"
  >
    <EnergyLabel
      ref="energyLabel"
      :alignment="datasheetAlignment"
      :energy-class="getEnergyClass(energyEfficiency)"
      :products="transformedProducts"
      class="energy-label-container"
      is-internal-link
      @click="handleLightboxOpen"
      @open-document="showDocument"
    />
    <ModalPortal
      ref="lightbox"
      @close="handleLightboxClose"
    >
      <Slider>
        <template
          v-for="(bundledProduct, index) in bundledProductsWithEnergyEfficiency"
        >
          <div
            :key="index"
            class="energy-label-slide"
          >
            <div class="energy-label-slide__head">
              <EnergyLabel
                :energy-class="getEnergyClass(bundledProduct.energyEfficiency)"
                :products="[extractedProducts.find((product) => !!product)]"
                class="energy-label-slide__head-label"
              />
              <strong>{{ bundledFamilyNameWithCount(bundledProduct.family.name) }}</strong>
            </div>
            <GoliathImage
              v-if="hasImage(bundledProduct.energyEfficiency)"
              :alt="bundledProduct.family.name"
              :path="getImagePath(bundledProduct.energyEfficiency)"
            />
          </div>
        </template>
      </Slider>
    </ModalPortal>
    <PdfViewer
      v-if="documentPath !== ''"
      :path="documentPath"
      @close="documentPath = ''"
    />
  </div>
</template>

<script>
import { attachmentUrl } from '@/lib/attachment-route';
import { EnergyLabel, Slider } from '@i22-td-smarthome/component-library';
import { countBy, uniqBy } from 'lodash';
import { energyEfficiencyClasses, extractedProducts, productsWithEnergyEfficiency } from '@i22/rocket';
import GoliathImage from '@/components/goliath-image.vue';
import ModalPortal from '@/components/modal-portal.vue';
import PdfViewer from '@/components/pdf-viewer.vue';

export default {
  name: 'EnergyLabelContainer',
  components: {
    PdfViewer,
    ModalPortal,
    GoliathImage,
    EnergyLabel,
    Slider,
  },
  props: {
    product: {
      type: Object,
      default: undefined,
    },
    datasheetAlignment: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'bottom', 'justify', 'left'].includes(value);
      },
    },
  },
  data() {
    return {
      lightboxLinkRef: null,
      documentPath: '',
    };
  },
  computed: {
    hasEnergyLabel() {
      return this.energyEfficiencyClasses.length > 0;
    },
    energyEfficiency() {
      return this.energyEfficiencyClasses.find((efficiencyClass) => !!efficiencyClass);
    },
    extractedProducts() {
      if (!this.product) return [];
      return extractedProducts(this.product);
    },
    energyEfficiencyClasses() {
      return energyEfficiencyClasses(this.extractedProducts);
    },
    productsWithEnergyEfficiency() {
      return productsWithEnergyEfficiency(this.extractedProducts);
    },
    transformedProducts() {
      return this.extractedProducts.map((product) => ({
        name: product.name,
        energyEfficiency: {
          class: product?.energyEfficiency?.class,
          labelImagePath: this.getImagePath(product?.energyEfficiency),
          documentPath: this.getDocumentPath(product?.energyEfficiency),
        },
      }));
    },
    bundledProductsWithEnergyEfficiency() {
      return uniqBy(this.productsWithEnergyEfficiency, (p) => p.family.name);
    },
    bundledProductFamilyCounts() {
      return countBy(this.productsWithEnergyEfficiency, (p) => p.family.name);
    },
  },
  methods: {
    handleLightboxOpen(lightboxLinkRef) {
      if (lightboxLinkRef) {
        this.lightboxLinkRef = lightboxLinkRef.focusOrigin;
      }

      this.$refs.lightbox.open();
    },
    handleLightboxClose() {
      if (!this.lightboxLinkRef) {
        return;
      }

      this.lightboxLinkRef.focus();
    },
    getImagePath(energyEfficiency) {
      const labelImage = energyEfficiency?.labelImage;

      if (!labelImage) {
        return null;
      }

      return attachmentUrl(labelImage);
    },
    hasImage(energyEfficiency) {
      return !!this.getImagePath(energyEfficiency);
    },
    getDocumentPath(energyEfficiency) {
      const document = energyEfficiency?.document;

      if (!document) {
        return null;
      }

      return attachmentUrl(document);
    },
    getEnergyClass(energyEfficiency) {
      const { class: energyClass } = energyEfficiency;

      return energyClass;
    },
    bundledFamilyNameWithCount(familyName) {
      const count = this.bundledProductFamilyCounts[familyName] || 0;
      if (count <= 1) return familyName;
      return `${count} × ${familyName}`;
    },
    showDocument(path) {
      this.documentPath = path;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.energy-label-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &-label {
      line-height: .9;
      margin-right: .75rem;
      margin-top: -2px;
      @include font-size(24px, 30px);
    }
  }
}
</style>
