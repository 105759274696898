<template>
  <div class="filter-accordion">
    <div class="filter-accordion__item-group">
      <FilterAccordionItem
        v-if="hasFilter('colorFilter')"
        label="Farbe"
        class="filter-accordion__item"
        :active="isItemActive('colorFilter')"
        :selected-filter="selectedColorFilter"
        @click.native="() => setActiveFilter('colorFilter')"
      >
        <ColorSelector
          v-model="form.fields.filter.colorSlugs"
          :aggregations="aggregations.colors"
          :active="isItemActive('colorFilter')"
          @change="onChange"
        />
      </FilterAccordionItem>
      <FilterAccordionItem
        v-if="hasFilter('brandFilter')"
        label="Hersteller"
        class="filter-accordion__item"
        :active="isItemActive('brandFilter')"
        :selected-filter="selectedBrandFilter"
        @click.native="() => setActiveFilter('brandFilter')"
      >
        <BrandSelector
          v-model="form.fields.filter.brandSlugs"
          :aggregations="aggregations.brands"
          :active="isItemActive('brandFilter')"
          @change="onChange"
          @reset="onResetBrands"
        />
      </FilterAccordionItem>
      <FilterAccordionItem
        v-if="hasFilter('compatibilityFilter')"
        label="Kompatibel mit Hersteller"
        class="filter-accordion__item"
        :class="appCompatibilityOptions.length && 'filter-accordion__item-compatibility'"
        :active="isItemActive('compatibilityFilter')"
        :selected-filter="selectedCompatibilityFilter"
        @click.native="() => setActiveFilter('compatibilityFilter')"
      >
        <BrandSelector
          v-model="form.fields.filter.compatibleBrandSlugs"
          :aggregations="aggregations.compatibleBrands"
          :active="isItemActive('compatibilityFilter')"
          @change="onChange"
          @reset="onResetCompatibility"
        />
        <Typography
          variant="title-bold"
          class="filter-accordion__heading"
        >
          Kompatibel mit Modell
        </Typography>
        <ProductFamilySelector
          v-model="form.fields.filter.compatibleProductFamilySlugs"
          :aggregations="aggregations.compatibleProductFamilies"
          :active="isItemActive('compatibilityFilter')"
          @change="onChange"
          @reset="onResetCompatibility"
        />
        <AppCompatibilityFilter
          v-model="form.fields.filter.productTagSlugs"
          :options="appCompatibilityOptions"
          :active="isItemActive('compatibilityFilter')"
          @change="onChange"
          @reset="onResetCompatibility"
        />
      </FilterAccordionItem>
      <FilterAccordionItem
        v-if="hasFilter('priceFilter')"
        label="Preisspanne"
        class="filter-accordion__item"
        :active="isItemActive('priceFilter')"
        :selected-filter="selectedPriceFilter"
        @click.native="() => setActiveFilter('priceFilter')"
      >
        <PriceRangeSelector
          :value="form.fields.filter.onetimePrice"
          :initial-max="maxPrice"
          :discounted="form.fields.filter.discounted"
          :active="isItemActive('priceFilter')"
          @input="updatePrices"
        />
      </FilterAccordionItem>
    </div>
    <div class="filter-accordion__bottom">
      <CommonButton
        class="filter-accordion__reset-button"
        variant="secondary"
        @click="reset"
      >
        Alle Filter löschen
      </CommonButton>
      <CommonButton
        class="filter-accordion__submit-button"
        :disabled="!hasProducts"
        @click="submit"
      >
        {{ variantsCount }} Produkte anzeigen
      </CommonButton>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep, debounce, omit, isEqual, isEmpty,
} from 'lodash';
import FilterAccordionItem from '@/components/filter-accordion-item.vue';
import PriceRangeSelector from '@/components/price-range-selector.vue';
import ColorSelector from '@/components/color-selector.vue';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';
import CommonButton from '@/components/common-button.vue';
import BrandSelector from '@/components/brand-selector.vue';
import ProductFamilySelector from '@/components/product-family-selector.vue';
import Typography from '@/components/typography.vue';
import AppCompatibilityFilter from '@/components/filters/app-compatibility-filter.vue';

const FIELDS = {
  filter: {
    productCategoryPaths: [],
    brandSlugs: [],
    colorSlugs: [],
    compatibleBrandSlugs: [],
    compatibleProductFamilySlugs: [],
    discounted: undefined,
    onetimePrice: {
      min: undefined,
      max: undefined,
    },
    productTagSlugs: [],
    listed: true,
    productAvailable: true,
    types: [
      'TANGIBLE',
    ],
  },
  search: undefined,
};

const MAX_PRICE = 2000;

export default {
  name: 'FilterAccordion',
  components: {
    AppCompatibilityFilter,
    FilterAccordionItem,
    PriceRangeSelector,
    ColorSelector,
    CommonButton,
    BrandSelector,
    ProductFamilySelector,
    Typography,
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Object,
      required: true,
    },
    aggregations: {
      type: Object,
      required: true,
    },
    variantsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        fields: mergeFilterVariables(FIELDS, this.variables),
      },
      activeFilter: null,
      givenFilter: [],
      maxPrice: MAX_PRICE,
      availableAppCompatibilityTags: {
        mzabasic: {
          label: 'MagentaZuhause App Basic *',
          labelSelected: 'MagentaZuhause App Basic',
        },
        mzapro: {
          label: 'MagentaZuhause App Pro',
          labelSelected: 'NEU: MagentaZuhause App Pro',
        },
        mshfree: {
          label: 'Magenta SmartHome *',
          labelSelected: 'Magenta SmartHome',
        },
        mshpro: {
          label: 'Magenta SmartHome Pro',
          labelSelected: 'Magenta SmartHome Pro',
        },
      },
    };
  },
  computed: {
    appCompatibilityOptions() {
      // Get product tags matching app tags and use order from label/value mapping
      return Object.keys(this.availableAppCompatibilityTags)
        .map((appTagValue) => {
          const productTag = this.aggregations.productTags?.find(
            (appProductTag) => appProductTag?.tag?.slug === appTagValue,
          );

          if (!productTag) {
            return null;
          }

          const appTag = this.availableAppCompatibilityTags[appTagValue];

          // Assign label for specific app tag from label/value mapping
          return {
            ...productTag,
            label: appTag?.label || productTag?.tag?.name,
            labelSelected: appTag?.labelSelected || productTag?.tag?.name,
          };
        })
        .filter(Boolean);
    },
    hasProducts() {
      return this.variantsCount > 0;
    },
    selectedPriceFilter() {
      const priceArray = [];
      if (this.form.fields.filter.onetimePrice) {
        const priceRange = [];
        // onetimePrice shouldn't be set if slider(min)
        // is 0 and slider(max) ist maxValue
        if (this.form.fields.filter.onetimePrice.min !== 0
          || this.form.fields.filter.onetimePrice.max !== this.maxPrice) {
          Object.values(this.form.fields.filter.onetimePrice)
            .forEach((value) => {
              if (value !== undefined) priceRange.push(value);
            });
        }
        let priceRangeString = '';
        // min oder max
        if (priceRange.length) {
          if (priceRange.length === 1) {
            // min oder max
            if (this.form.fields.filter.onetimePrice.min) {
              priceRangeString = `von ${priceRange[0]} €`;
            } else {
              priceRangeString = `bis ${priceRange[0]} €`;
            }
          } else {
            // min und max
            priceRangeString = `von ${priceRange[0]} € bis ${priceRange[1]} €`;
          }
          priceArray.push(priceRangeString);
        }
      }
      if (this.form.fields.filter.discounted) {
        priceArray.push('Nur Angebote');
      }
      return priceArray;
    },
    selectedColorFilter() {
      const selectedColorNames = this.aggregations.colors
        .filter((entry) => this.form.fields.filter.colorSlugs
          .includes(entry.color.slug))
        .map((entry) => entry.color.name);
      return selectedColorNames;
    },
    selectedBrandFilter() {
      const selectedBrandNames = this.aggregations.brands
        .filter((entry) => this.form.fields.filter.brandSlugs
          .includes(entry.brand.slug))
        .map((entry) => entry.brand.name);
      return selectedBrandNames;
    },
    selectedCompatibilityFilter() {
      const selectedBrandNames = this.aggregations.compatibleBrands
        .filter((entry) => this.form.fields.filter.compatibleBrandSlugs
          .includes(entry.brand.slug))
        .map((entry) => entry.brand.name);

      const selectedProductFamilySlugs = this.aggregations.compatibleProductFamilies
        .filter((entry) => this.form.fields.filter.compatibleProductFamilySlugs
          .includes(entry.productFamily.slug))
        .map((entry) => entry.productFamily.model);

      const selectedAppCompatibilitySlugs = this.appCompatibilityOptions
        .filter((entry) => this.form.fields.filter.productTagSlugs
          .includes(entry.tag.slug))
        .map((entry) => entry.labelSelected);

      const selectedCompatibilities = [
        ...selectedBrandNames,
        ...selectedProductFamilySlugs,
        ...selectedAppCompatibilitySlugs,
      ];
      return selectedCompatibilities;
    },
  },
  watch: {
    variables: {
      handler(variables) {
        this.form.fields = mergeFilterVariables(FIELDS, variables);
      },
      deep: true,
    },
    isOpened: {
      handler(becameOpened) {
        // only as soon as filter-modal -> filter-accordion isOpened
        // one is always active;
        // Default: first Filter open
        if (!this.activeFilter && becameOpened) {
          // eslint-disable-next-line prefer-destructuring
          this.activeFilter = this.givenFilter[0];
        }
      },
    },
  },
  mounted() {
    this.givenFilter = this.checkGivenFilter();
  },
  methods: {
    // hasFilter START
    // doesn't want filter which was there in the beginning
    // due to reconfiguration of e.g. priceRange
    // to dis-/appear all of a sudden
    // set once in the start and then not again -> no computed!
    hasFilter(filterName) {
      return this.givenFilter.includes(filterName);
    },
    checkGivenFilter() {
      const givenFilter = [];
      const hasColorFilter = !isEmpty(this.aggregations.colors);
      const hasPriceFilter = this.hasProducts;
      const hasBrandFilter = !isEmpty(this.aggregations.brands);

      if (hasColorFilter) givenFilter.push('colorFilter');
      if (hasPriceFilter) givenFilter.push('priceFilter');
      if (hasBrandFilter) givenFilter.push('brandFilter');

      const hasCompatibilityFilter = !isEmpty(this.aggregations.compatibleBrands)
        || !isEmpty(this.aggregations.compatibleProductFamilies)
        || !isEmpty(this.appCompatibilityOptions);
      if (hasCompatibilityFilter) givenFilter.push('compatibilityFilter');
      return givenFilter;
    },
    // hasFilter END -> see comment above
    setActiveFilter(filterName) {
      this.activeFilter = filterName;
    },
    isItemActive(itemName) {
      return itemName === this.activeFilter;
    },
    onChange() {
      const fields = cloneDeep(this.form.fields);
      this.$emit('change', fields);
    },
    onResetBrands() {
      const fields = cloneDeep(this.form.fields);

      fields.filter = {
        ...fields.filter,
        brandSlugs: [],
      };

      this.$emit('change', fields);
    },
    onResetCompatibility() {
      const fields = cloneDeep(this.form.fields);

      fields.filter = {
        ...fields.filter,
        compatibleBrandSlugs: [],
        compatibleProductFamilySlugs: [],
        productTagSlugs: [],
      };
      this.$emit('change', fields);
    },
    onChangePrices: debounce(function onChange() {
      const fields = cloneDeep(this.form.fields);
      this.$emit('change', fields);
    }, 300),
    updatePrices(prices) {
      const filter = {
        ...this.form.fields.filter,
        ...prices,
      };
      this.form.fields.filter = filter;
      this.onChangePrices();
    },
    submit() {
      if (!this.hasProducts) return;
      const fields = cloneDeep(this.form.fields);
      this.$emit('submit', fields);
    },
    reset() {
      const filterWithoutCategoryPaths = omit(
        this.form.fields.filter, 'productCategoryPaths',
      );
      const fieldsWithoutCategoryPaths = omit(
        FIELDS.filter, 'productCategoryPaths',
      );
      if (!isEqual(filterWithoutCategoryPaths, fieldsWithoutCategoryPaths)) {
        this.$emit('reset', FIELDS);
      }
    },
  },
};
</script>

<style lang="scss">
.filter-accordion {
  &__item-compatibility {
    &.filter-accordion-item--active {
      .filter-accordion-item__slot {
        height: 680px;
      }
    }
  }

  &__heading {
    padding: 72px 30px 24px 30px;
  }

  &__submit-button {
    height: 72px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    padding: 48px 24px;
    height: 100%;
    justify-content: space-between;
  }
}

</style>
