<template>
  <div
    class="variant-bundle-content"
  >
    <BulletPointList
      :list="list"
      class="variant-bundle-content__list"
    >
      <template slot-scope="entry">
        <span class="variant-bundle-content__name">{{ entry.item.text }}<br>
          <span class="variant-bundle-content__variants">{{ entry.item.variants }}</span>
        </span>
      </template>
    </BulletPointList>
    <div
      class="variant-bundle-content__button"
      :class="{ 'variant-bundle-content__button--right' : !oneColumn }"
    >
      <CommonButton
        v-if="isConfigurable"
        variant="secondary"
        @click="configureBundle"
      >
        Set Konfigurieren
      </CommonButton>
    </div>
  </div>
</template>

<script>
import BulletPointList from '@/components/bullet-point-list.vue';
import CommonButton from '@/components/common-button.vue';

export default {
  name: 'VariantBundleContent',
  components: { CommonButton, BulletPointList },
  props: {
    focusedItemIndex: { type: Number, default: undefined },
    bundleItems: { type: Array, default: () => [] },
    slug: { type: String, required: true },
  },
  computed: {
    list() {
      return this.bundleItems.map((item) => ({
        text: item.family.name,
        variants: this.getActiveVariantNames(item).join(', '),
      }));
    },
    oneColumn() {
      return this.list <= 3;
    },
    isConfigurable() {
      return !!this.bundleItems.find((item) => this.hasMultipleVariants(item.properties));
    },
  },
  methods: {
    hasMultipleVariants(properties) {
      return properties.find((property) => property.variants.length > 1);
    },
    getActiveVariantNames({ properties }) {
      return properties.map((property) => property.variants.find((variant) => variant.active)?.name || null)
        .filter((entry) => entry !== null);
    },
    configureBundle() {
      // catch to avoid NavigationDuplicated error
      this.$router.replace({ path: `${this.slug}/configure` }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shelf.scss";

.variant-bundle-content {
  $margin-bottom: 12px;
  $button-height: 72px;

  display: flex;
  flex-direction: column;
  padding-right: 50px;

  &__list {
    height: $shelf-content-height - $button-height - $margin-bottom; // button + margin
  }

  &__name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__variants {
    color: #777;
  }
  &__button {
    height: $button-height;
    margin-bottom: $margin-bottom;
    margin-left: 50px;
    white-space: nowrap;

    &--right {
      text-align: right;
    }
  }
}
</style>
