import { mixin as Clickaway } from 'vue-clickaway';
import Openable from '@/mixins/openable';

export default {
  mixins: [Clickaway, Openable],
  emits: ['clickaway'],
  methods: {
    async onClickaway() {
      if (this.isClosed) return false;
      await this.close();
      this.$emit('clickaway');
      return true;
    },
  },
};
