<template>
  <div class="cart-paypal-page">
    <Header
      :show-back-button="!isCartTransmitted"
      :show-breadcrumb="false"
      :show-cart="false"
    />
    <div class="page-grid">
      <div class="cart-paypal-page__heading-section">
        <Typography variant="pre-heading">
          Per Smartphone mit Paypal
        </Typography>
        <Typography
          variant="heading-large"
          class="cart-paypal-page__heading"
        >
          So geht's weiter
        </Typography>
      </div>
      <div
        v-if="!isCartTransmitted"
        class="cart-paypal-page__orderstep"
      >
        <div class="cart-paypal-page__orderstep-number">
          <Typography variant="order-number">
            01
          </Typography>
        </div>
        <div class="cart-paypal-page__orderstep-description">
          <Typography
            variant="flow-text"
          >
            Bitte scannen Sie den QR-Code mit Ihrem Smartphone. Sie werden auf Ihrem Smartphone zu Paypal weitergleitet.
          </Typography>
        </div>
        <div
          class="cart-paypal-page__orderstep-visual"
        >
          <div
            v-if="url"
            class="cart-paypal-page__qr-code-container"
          >
            <a
              :href="url"
              :title="url"
              target="_blank"
            >
              <VueQrcode
                :value="url"
                :options="qrOptions"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="isCartTransmitted"
        class="cart-paypal-page__orderstep"
      >
        <div

          class="cart-paypal-page__orderstep-number"
        >
          <Typography variant="order-number">
            02
          </Typography>
        </div>
        <div
          class="cart-paypal-page__orderstep-description"
        >
          <Typography
            variant="flow-text"
          >
            Der Warenkorb wurde an PayPal übertragen. Bitte schließen Sie die Bestellung auf dem Smartphone ab.
          </Typography>
        </div>
        <div class="cart-paypal-page__orderstep-visual">
          <div class="cart-paypal-page__phone">
            <img
              src="@/assets/images/phone.png"
              alt="Phone"
              class="cart-paypal-page__phone-image"
            >
            <PaypalDarkIcon class="cart-paypal-page__phone-icon" />
          </div>
        </div>
        <router-link
          to="/screensaver"
          class="cart-paypal-page__button"
        >
          <CommonButton>
            Zum Startscreen
          </CommonButton>
        </router-link>
      </div>
      <Footer
        v-if="!isCartTransmitted"
        :has-meta-links="false"
      />
    </div>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import axios from 'axios';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import CommonButton from '@/components/common-button.vue';
import Typography from '@/components/typography.vue';
import PaypalDarkIcon from '@/assets/images/paypal-dark-icon.svg';
import actions from '@/store/actions';

export default {
  name: 'CartPaypalPage',
  components: {
    Header,
    Footer,
    VueQrcode,
    CommonButton,
    Typography,
    PaypalDarkIcon,
  },
  data() {
    return {
      isCartTransmitted: false,
      pollingInterval: null,
      orderKey: '',
      qrOptions: { width: 369, margin: 0, errorCorrectionLevel: 'H' },
    };
  },

  computed: {
    url() {
      if (this.orderKey) {
        return `${window.location.origin}/paypal_redirect?order_key=${this.orderKey}`;
      }

      return '';
    },
  },

  mounted() {
    this.setup();
  },

  methods: {
    async setup() {
      try {
        await axios.post('/api/shop/paypal_checkout', {});
        await this.pollCheckoutStatus();

        this.pollingInterval = setInterval(this.pollCheckoutStatus.bind(this), 3000);
      } catch (error) {
        console.error(error);
        this.$router.push('/error?reason=FAILED_TO_SEND_TO_PAYPAL');
      }
    },

    async pollCheckoutStatus() {
      try {
        const result = await axios.get('/api/shop/order');

        if (!this.orderKey) {
          this.orderKey = result.data.order.orderKey;
        }

        if (['vs_paypal_transferred', 'empty'].includes(result.data.order.state)) {
          clearInterval(this.pollingInterval);
          this.isCartTransmitted = true;
          await this.$store.dispatch(actions.RESET_CART);
        }
      } catch (error) {
        console.error(error);
        this.$router.push('/error?reason=FAILED_TO_POLL_CART');
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
.cart-paypal-page {
  &__button {
    margin-top: 67px;
    grid-column: 2 / span 7;
  }

  &__heading-section {
    margin-top: 126px;
    grid-column: span 8;
  }

  &__heading {
    height: 220px;
    margin-bottom: 129px;
  }

  &__orderstep {
    grid-column: 1 / span 8;
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  &__orderstep-number {
    display: flex;
    height: 50px;
    align-items: baseline;
    padding-top: 3px;

    &::before {
      content: " ";
      width: 60px;
      border-bottom: 4px solid #e20074;
      margin-right: 10px;
    }
  }

  &__orderstep-description {
    grid-column: 2 / span 7;
  }

  &__orderstep-visual {
    grid-column: 1 / span 8;
    text-align: center;
    margin-top: 116px;
    margin-bottom: 74px;
  }

  &__qr-code-container {
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 47px;
    border: 2px solid #000;
    z-index: 0;

    &::before {
      position: absolute;
      content: " ";
      top: -5px;
      left: calc(50%);
      transform: translate(-50%, 0);
      height: calc(100% + 10px);
      width: calc(100% - 110px);
      background-color: #fff;
      z-index: -1;
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: -5px;
      transform: translate(0%, -50%);
      height: calc(100% - 110px);
      width: calc(100% + 10px);
      background-color: #fff;
      z-index: -1;
    }
  }

  &__phone {
    position: relative;
    height: 763px;
    display: grid;
    justify-items: center;
  }

  &__phone-image {
    position: absolute;
  }

  &__phone-icon {
    position: absolute;
    top: 200px;
  }
}
</style>
