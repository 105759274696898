<template>
  <div class="range-selector">
    <div
      ref="rangeSelectorBar"
      class="range-selector__bar"
    >
      <div
        class="range-selector__bar range-selector__bar--inactive range-selector__bar--inactive-before"
        :style="getInactiveBarBeforeStyle"
      />
      <RangeSlider
        ref="fromSlider"
        class="range-selector__slider range-selector__slider--from"
        :bound-min="fromSliderBounds.min"
        :bound-max="fromSliderBounds.max"
        :value="internalValue.min"
        @input="onInputMin"
      />
      <RangeSlider
        ref="toSlider"
        class="range-selector__slider range-selector__slider--to"
        :bound-min="toSliderBounds.min"
        :bound-max="toSliderBounds.max"
        :value="internalValue.max"
        @input="onInputMax"
      />
      <div
        class="range-selector__bar range-selector__bar--inactive range-selector__bar--inactive-after"
        :style="getInactiveBarAfterStyle"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import RangeSlider from '@/components/range-slider.vue';

export default {
  name: 'RangeSelector',
  components: {
    RangeSlider,
  },
  props: {
    // in fact the real min and max (pixel) values which are available to select
    // not the barWidth (clientWidth on Screen)
    value: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      fromSliderBoundsMax: null,
      toSliderBoundsMin: null,
      rangeSelectorBarWidth: null,
      sliderWidth: null,
      initialAnimationDone: false,
    };
  },
  computed: {
    // maximal slideable pixel Value
    // length of rangeSelectorBar - both sliders
    // which have to fit in there as well
    // is needed to convert real given Range e.g. from 0 to 2000
    // to the actual slideable pixelRange GSAP Draggable is working with
    pixelMax() {
      // from- and toSlider
      const sliderQuantity = 2;
      return this.getRangeSelectorBarWidth() - (this.sliderWidth * sliderQuantity);
    },
    fromSliderBounds() {
      return {
        min: 0,
        max: this.fromSliderBoundsMax,
      };
    },
    toSliderBounds() {
      return {
        min: this.toSliderBoundsMin,
        max: 780,
      };
    },
    getInactiveBarBeforeStyle() {
      if (!this.sliderWidth) return undefined;
      return `width: ${this.internalValue.min + (this.sliderWidth / 2)}px`;
    },
    getInactiveBarAfterStyle() {
      if (!this.sliderWidth) return undefined;
      return `width: ${this.pixelMax - this.internalValue.max + (this.sliderWidth / 2)}px`;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.internalValue = value;
        this.setBounds();
      },
    },
    active: {
      handler(becomesActive) {
        if (becomesActive && !this.initialAnimationDone) {
          this.setBounds();
          this.animateToSlider();
          this.initialAnimationDone = true;
          this.$emit('becomes-active', this.pixelMax);
        }
      },
    },
  },
  mounted() {
    this.initRangeSelector();
  },
  methods: {
    setBounds() {
      this.rangeSelectorBarWidth = this.getRangeSelectorBarWidth();
      this.sliderWidth = this.getSliderWidth();
      this.toSliderBoundsMin = this.internalValue.min;
      this.fromSliderBoundsMax = this.internalValue.max;
    },
    updateRange() {
      // update without this.$refs.slider.onDraggableEvent();
      // due to filter reset which isn't possible when
      // slider resets and sets priceRange anew
      this.$refs.fromSlider.onDraggableEvent();
      this.$refs.toSlider.onDraggableEvent();
      this.$refs.fromSlider.draggableInstance[0].update();
      this.$refs.toSlider.draggableInstance[0].update();
    },
    animateToSlider() {
      if (!this.$refs.toSlider) return;
      const tl = gsap.timeline();
      tl.to(this.$refs.toSlider.$el, {
        x: this.toSliderBounds.max,
        onUpdate: this.updateRange,
        duration: 2,
      }).play();
    },
    resetAnimateSlider() {
      if (!this.$refs.toSlider || !this.$refs.fromSlider) return;
      const tl = gsap.timeline();
      tl.to(this.$refs.toSlider.$el, 1, {
        x: this.toSliderBounds.max,
        onUpdate: this.updateRange,
        duration: 2,
      }, 0);
      tl.to(this.$refs.fromSlider.$el, 1, {
        x: 0,
        onUpdate: this.updateRange,
        duration: 2,
      }, 0);
    },
    initRangeSelector() {
      this.setBounds();
    },
    onInputMin(min) {
      this.internalValue.min = min;
      this.$emit('input', this.internalValue);
      this.setBounds();
    },
    onInputMax(max) {
      this.internalValue.max = max;
      this.$emit('input', this.internalValue);
      this.setBounds();
    },
    getRangeSelectorBarWidth() {
      if (!this.$refs.rangeSelectorBar) return undefined;
      return this.$refs.rangeSelectorBar.clientWidth;
    },
    getSliderWidth() {
      if (!this.$refs.fromSlider) return undefined;
      return this.$refs.fromSlider.$el.clientWidth;
    },
  },
};
</script>

<style lang="scss">
.range-selector {
  &__bar {
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    border-radius: 5px;
    background-color: #E20074;
    box-shadow: inset 1px 2px 7px 0 rgba(0,0,0,0.3);
    &--inactive {
      position: absolute;
      background-color: #EDEDED;
      z-index: 1;

      &-before {
        top: 0;
        left: -1px;
      }
      &-after {
        top: 0;
        right: -1px;
      }
    }
  }
}
</style>
