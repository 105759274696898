<template>
  <div
    class="shelf-entry"
  >
    <Typography
      v-if="showTitle"
      class="shelf-entry__title"
      variant="title-bold"
    >
      {{ title }}
    </Typography>

    <div :class="containerClass">
      <slot />
    </div>

    <div
      class="shelf-entry__board"
    >
      <div class="shelf-entry__board shelf-entry__board--left" />
      <div class="shelf-entry__board shelf-entry__board--main" />
      <div
        v-if="hasAlternativeShelfEnd"
        class="shelf-entry__board shelf-entry__board--right-alternative"
      />
      <div
        v-else
        class="shelf-entry__board shelf-entry__board--right"
      />
    </div>
  </div>
</template>

<script>
import Typography from '@/components/typography.vue';

export default {
  name: 'ShelfEntry',
  components: {
    Typography,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    containerClass() {
      return this.variant ? `shelf-entry__${this.variant}` : null;
    },
    hasAlternativeShelfEnd() {
      return this.variant === 'recommendations';
    },
  },
};
</script>

<style lang="scss">
.shelf-entry {
  margin: 0 10px 20px 40px;

  &__recommendations {
    display: flex;
    height: 317px;
    justify-content: flex-start;
    padding: 0 50px;
    position: relative;
    top: 20px;
  }

  &__title {
    padding-left: 50px;
    white-space: nowrap;
  }

  &__board {
    display: flex;
    height: 120px;
    background-color: transparent;
    background-repeat: no-repeat;

    &--main {
      flex: 1;
      background-image: url("../assets/images/alternative-shelf-main.png");
      background-repeat: repeat-x;
    }

    &--left {
      width: 100px;
      background-image: url("../assets/images/alternative-shelf-beginning.png");
      flex-shrink: 0;
    }

    &--right {
      width: 100px;
      background-image: url("../assets/images/alternative-shelf-end.png");
      flex-shrink: 0;
    }

    &--right-alternative {
      width: 100px;
      background: url("../assets/images/alternative-shelf-beginning.png");
      flex-shrink: 0;
      transform: scaleX(-1);
    }
  }
}
</style>
