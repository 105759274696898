<template>
  <div class="offer-configurator-page">
    <Header
      :show-breadcrumb="false"
      :show-cart="false"
      :back-button-path="productPath"
    />
    <div class="heading-section">
      <div class="heading-section__title">
        Set Konfigurieren
      </div>
      <div
        v-if="offer"
        class="heading-section__subline"
      >
        {{ name }}
      </div>
    </div>
    <div class="main-section">
      <div
        ref="shelf"
        class="bundle-item-group"
        :class="{'bundle-item-group--scrollable':isScrollable}"
      >
        <BundleItem
          v-for="bundleItem in bundleItems"
          :key="bundleItem.position + Math.random()"
          :item="bundleItem"
        />
      </div>
      <div
        v-if="offer"
        class="add-to-cart-prompt"
      >
        <Prices
          :legal-text="legalTexts"
          :prices-data="prices"
        />
        <AddToCartButton
          ref="addToCartButton"
          :article-number="articleNumber"
          @item-added="redirectToOfferPage"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import BundleItem from '@/components/product-detail/offer-configurator/bundle-item.vue';
import Prices from '@/components/prices.vue';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import { getSimplifiedPrices } from '@/lib/goliath/simplify-offer';
import { transformOfferAlternatives } from '@/lib/transform-offer-alternatives';
import Footer from '@/components/footer.vue';
import AddToCartButton from '@/components/add-to-cart-button.vue';
import { fetchOfferBySlugs } from '@/lib/goliath/offer-by-slugs';

gsap.registerPlugin(Draggable);
const MINIMUM_DRAG_MOVEMENT = 10;
const BOARD_PADDING = 50;
const MIN_ITEMS_FOR_SCROLLBAR = 6;

export default {
  name: 'OfferConfiguratorPage',
  components: {
    Header,
    BundleItem,
    Footer,
    AddToCartButton,
    Prices,
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name === 'variants') {
      await this.setOffer(to.params.slug);
      this.initShelf();
    }
    next();
  },
  props: {
    singleShelfDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offer: null,
      bundleItems: [],
      draggableInstances: [],
      minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
      shelfWidth: 1080,
      boardPadding: BOARD_PADDING,
      isScrollable: false,
      enterAnimationDone: false,
    };
  },
  computed: {
    name() {
      return this.offer.product.name;
    },
    prices() {
      return getSimplifiedPrices(this.offer.prices);
    },
    legalTexts() {
      return [];
    },
    articleNumber() {
      return this.offer.product.articleNumber;
    },
    slug() {
      return this.offer.product.slug;
    },
    draggable() {
      return this.draggableInstances.length ? this.draggableInstances : null;
    },
    productPath() {
      return `/offer/${this.offer?.product?.slug}`;
    },
  },
  watch: {
    shelfWidth: {
      handler() {
        this.initDraggableBoundaries();
      },
    },
  },
  created() {
    this.setOffer();
  },
  mounted() {
    setTimeout(this.initShelf, 16);
    this.calcScrollbarVisibility();
    setTimeout(this.initShelf, 1000);
  },
  methods: {
    async setOffer(slug = null) {
      if (!slug && !this?.$route?.params) return;
      this.offer = await fetchOfferBySlugs({ productSlug: slug || this.$route?.params?.slug });
      if (!this.offer) return;
      this.bundleItems = transformOfferAlternatives(this.offer);
    },
    initShelf() {
      this.initializeDraggableOn(this.singleShelfDraggable ? '.bundle-item' : '.bundle-item-group');
      this.shelfWidth = this.$refs.shelf.clientWidth;
      this.initDraggableBoundaries();
      this.enterAnimation();
    },
    initializeDraggableOn(selector) {
      this.draggableInstances = Draggable
        .create(selector, this.getDraggableParams());
    },
    getDraggableParams() {
      return {
        type: 'x',
        edgeResistance: 0.7,
        inertia: true,
        minimumMovement: this.minimumDragMovement,
        zIndexBoost: false,
      };
    },
    setBounds(boundsMin, boundsMax) {
      if (!this.draggable) return;
      this.draggable.map((shelf) => shelf.applyBounds({
        minX: boundsMin,
        maxX: boundsMax,
      }));
    },
    initDraggableBoundaries() {
      const boundsMin = 0;
      const boundsMax = (this.shelfWidth - window.innerWidth) * -1;
      this.setBounds(boundsMin, boundsMax);
    },
    enterAnimation() {
      if (this.enterAnimationDone) {
        return;
      }

      this.enterAnimationDone = true;
      gsap.timeline().from('.bundle-item-group', {
        x: '100%',
        opacity: 0,
        duration: 1,
      });
    },
    calcScrollbarVisibility() {
      this.isScrollable = this.bundleItems.length >= MIN_ITEMS_FOR_SCROLLBAR;
    },
    redirectToOfferPage() {
      this.$router.push({
        name: 'offer',
        slug: this.slug,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";
.offer-configurator-page {
  height: calc(100vh - 220px); // view height - footer height = page height
  display: flex;
  flex-direction: column;
  .heading-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 32px;
    gap: 8px;
    text-transform: uppercase;
    &__title {
      font-weight: bold;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: 0.6px;
    }
    &__subline {
      font-weight: bold;
      font-size: 30px;
      line-height: 100%;
      letter-spacing: 0.75px;
      color: #8d8d8d;
    }
  }

  .main-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  .bundle-item-group {
    align-self: flex-start;
    padding: 18px 32px;

    &--scrollable{
      max-height: 1350px;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: color(grey, 22000);
      }

      &::-webkit-scrollbar-thumb {
        background: $color-primary;
      }
    }
  }

  .add-to-cart-prompt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150px;
    gap: 36px;
  }
}
</style>
