<template>
  <div
    class="screensaver"
  >
    <div
      v-if="tilesData"
      class="screensaver__content"
    >
      <div class="screensaver__top-bar" />
      <ScreensaverAnimatedTiles
        class="screensaver__animated-tiles"
        :tiles-data="tilesData"
        :has-video="hasVideo"
        :video-is-playing="videoIsPlaying"
        @show-video="showVideo"
        @hide-video="hideVideo"
      />
      <router-link
        class="screensaver__bottom-button"
        :to="path"
      >
        <img
          class="screensaver__button-image"
          src="../assets/images/hand_animated.webp"
        >
        <Typography
          variant="hint"
          class="screensaver__button-label"
        >
          Tippen & shoppen
        </Typography>
      </router-link>
    </div>
    <router-link
      :to="path"
    >
      <VideoElement
        v-if="videoVisible"
        class="screensaver__video"
        :is-active="videoVisible"
        :src="videoSrc"
        :loop="false"
        @video-ended="videoEndHandler"
      />
    </router-link>
  </div>
</template>

<script>
import { shuffle } from 'lodash';
import { mapGetters } from 'vuex';
import ScreensaverAnimatedTiles from '@/components/screensaver-animated-tiles.vue';
import Typography from '@/components/typography.vue';
import VideoElement from '@/components/video-element.vue';

const CATEGORY_TILES_DATA = [
  {
    type: 'screensaverCategoryTile',
    label: 'Angebote',
    path: '/search/offers?prefab=rebate',
    imageSrc: '/content/content-images/virtual-shelf/clays/search-rebate.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'SmartHome',
    path: '/sub-categories/smarthome',
    imageSrc: '/content/content-images/screensaver/9a767dc75a3ca1d80f97673768249106/gluehbirne_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Smartphones',
    path: '/categories/smartphones-und-handys',
    imageSrc: '/content/content-images/screensaver/454f82a3f8aa7aa90298dbecc2857cae/20_smartphone_0000.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Tablets',
    path: '/categories/tablets',
    imageSrc: '/content/content-images/screensaver/f3274f4b0a74f49cde1f887d6510234d/21_tablet_0000.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Zubehör',
    path: '/sub-categories/zubehoer',
    imageSrc: '/content/content-images/screensaver/d66546ef81d89fdc40a52ec6295f8c57/zubehoer_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Kopfhörer',
    path: '/categories/zubehoer/kopfhoerer',
    imageSrc: '/content/content-images/screensaver/07a2109219bf8eb5dba3f99279b7d1d9/kopfhoerer_0036.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Haushaltsgeräte & Freizeit',
    path: '/categories/zubehoer/innovationen-und-gadgets',
    imageSrc: '/content/content-images/screensaver/a87d4510f914ec7423cd860badd04f41/fryer.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Lautsprecher',
    path: '/categories/zubehoer/lautsprecher',
    imageSrc: '/content/content-images/screensaver/551040345314687b92001485bc99c030/lautsprecher-v2.png',
  },
  {
    type: 'screensaverCategoryTile',
    isTeufel: true,
    label: 'Teufel',
    path: '/teufel',
    imageSrc: '/content/content-images/virtual-shelf/clays/teufel.png',
  },
];

function sanitizeOffer(offer) {
  return {
    type: 'screensaverOfferTile',
    label: offer.productName,
    path: offer.path,
    imageSrc: offer.imageSrc,
    defaultPrice: offer.defaultPrice,
    wasPrice: offer.wasPrice,
    highlight: offer.highlight,
    hasEnergyLabel: offer.hasEnergyLabel,
  };
}

export default {
  name: 'Screensaver',
  components: {
    VideoElement,
    ScreensaverAnimatedTiles,
    Typography,
  },
  props: {
    offers: {
      type: Array,
      required: true,
    },
    path: {
      type: String,
      default: '/start',
    },
  },
  data() {
    return {
      videoVisible: false,
      videoIsPlaying: false,
    };
  },
  computed: {
    ...mapGetters({
      getVideoByKey: 'preloadedVideos/getVideoByKey',
    }),
    videoSrc() {
      return this.getVideoByKey('activationScreen')?.url;
    },
    hasVideo() {
      if (!this.videoSrc) return false;
      return true;
    },
    tilesData() {
      // Combine categories and offers
      const combinedPool = [
        ...CATEGORY_TILES_DATA,
        ...this.offers?.map(sanitizeOffer) || [],
      ];

      // Shuffle the combined pool
      const shuffledPool = shuffle(combinedPool);

      const getNextItem = (index) => shuffledPool[index];

      // Ensure we have 12 unique items or duplicates on the same tile
      const tiles = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        const front = getNextItem(i);
        const back = getNextItem(i + 6) || front;
        tiles.push([
          front, back,
        ]);
      }

      return tiles;
    },
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
      this.videoIsPlaying = true;
    },
    hideVideo() {
      this.videoVisible = false;
    },
    videoEndHandler() {
      this.videoIsPlaying = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.screensaver {
  background-color: #000;
  height: 1920px;
  width: 1080px;
  position: relative;

  &__animated-tiles {
    display: flex;
    flex-wrap: wrap;
    height: calc(1920px - 274px - 116px);
    width: 100%;
  }

  &__top-bar {
    background-color: #2F3234;
    height: 88px;
    width: 100%;
  }

  &__bottom-button {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 274px;
    width: 100%;
    background-color: #E20074;
    border-top: 2px solid #E85CA4;

    &:visited {
      text-decoration: none;
      color: #FFFFFF;
    }
  }

  &__button-label {
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 60px;
    letter-spacing: 0.6px;
    line-height: 110px;
    position: absolute;
    bottom: 42px;
    margin-left: 1px;
  }

  &__button-image {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: calc(50% - 63px);
  }

  @keyframes scaleUpAndDisappear {
    0% { transform: translate(-50%, -50%) scale(0); opacity: 1 }
    10% { transform: translate(-50%, -50%) scale(1.3); opacity: 1 }
    20%, 100% { transform: translate(-50%, -50%) scale(1.7); opacity: 0 }
  }

  &__video {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1920px;
  }
}
</style>
