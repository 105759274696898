<template>
  <div
    class="variant-selector-basic"
    :class="{ 'variant-selector-basic--horizontal': horizontal }"
  >
    <VariantItem
      v-for="variant in variants"
      :key="variant.name"
      :is-active="variant.active"
      class="variant-selector-basic__item"
      @click.native.prevent="selectVariant(variant)"
    >
      <slot :variant="variant">
        {{ variant.name }}
      </slot>
    </VariantItem>
  </div>
</template>

<script>
import VariantItem from './variant-item.vue';

export default {
  name: 'VariantSelectorBasic',
  components: { VariantItem },
  props: {
    variants: { type: Array, default: () => [] },
    horizontal: { type: Boolean },
  },
  methods: {
    selectVariant(variant) {
      this.$emit('selectVariant', { variant });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.variant-selector-basic {
  display: inline-grid;
  grid-gap: 28px;
  grid-auto-flow: column;
  &--horizontal {
    grid-template-rows: repeat(1, 54px);
  }

  &:not(&--horizontal) {
    grid-template-rows: repeat(4, 54px);
    height: 280px;
  }

  &__item {
    cursor: pointer;
  }
}
</style>
