<template>
  <div class="help-page">
    <Header :show-breadcrumb="false" />
    <div class="help-page__intro">
      <Typography
        class="help-page__heading"
        variant="heading"
      >
        Hilfebereich
      </Typography>
      <Typography variant="title">
        Hier finden Sie Antworten und Hilfestellungen zu häufig gestellten Anfragen
      </Typography>
    </div>
    <div class="help-page__faqs">
      <QuestionAndAnswer
        v-for="(qna, index) in qnas"
        :key="qna.question"
        :question="qna.question"
        :answer="qna.answer"
        :active="isActive(index)"
        @toggle="toggleActive(index)"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/header.vue';
import Typography from '@/components/typography.vue';
import QuestionAndAnswer from '@/components/question-and-answer.vue';
import Footer from '@/components/footer.vue';

export default {
  name: 'HelpPage',
  components: {
    Header,
    Footer,
    QuestionAndAnswer,
    Typography,
  },
  data() {
    return {
      currentIndex: -1,
      qnas: [],
    };
  },
  async mounted() {
    const response = await axios.get('/content/content-json/virtual-shelf/questions-and-answers.json');
    this.qnas = response.data.list;
  },
  methods: {
    toggleActive(index) {
      this.currentIndex = this.currentIndex === index ? -1 : index;
    },
    isActive(index) {
      return this.currentIndex === index;
    },
  },
};
</script>

<style lang="scss">
.help-page {
  &__intro {
    margin: 0 30px 40px;
  }
  &__heading {
    margin: 0 0 12px;
  }
}
</style>
