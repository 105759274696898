<template>
  <div class="cart-transit-page">
    <div class="page-grid">
      <div class="cart-transit-page__heading-section">
        <Typography
          variant="pre-heading"
        >
          Bezahlung an der Kasse
        </Typography>
        <Typography
          variant="heading-large"
          class="cart-transit-page__heading"
        >
          So geht's weiter
        </typography>
      </div>
      <Typography
        variant="order-number"
        class="cart-transit-page__orderstep-number"
      >
        01
      </Typography>
      <div class="cart-transit-page__orderstep">
        <Typography
          variant="flow-text"
          class="cart-transit-page__text"
        >
          Merken Sie sich Ihren Bestellcode und wenden Sie sich an einen Mitarbeiter am Servicepoint. Dieser wird Ihre Adressdaten für den Versand aufnehmen und Ihnen die Rechnung ausstellen.
          <div class="cart-transit-page__order-code-line">
            Ihr Bestellcode
            <Typography variant="order-code">
              {{ code }}
            </Typography>
          </div>
        </Typography>
      </div>
      <Typography
        variant="order-number"
        class="cart-transit-page__orderstep-number"
      >
        02
      </Typography>
      <div class="cart-transit-page__orderstep">
        <Typography
          variant="flow-text"
          class="cart-transit-page__text"
        >
          Mit der Rechnung gehen Sie zur Kasse und bezahlen Ihre Bestellung. Der Auftrag wird anschließend bearbeitet und Ihnen innerhalb von 1-3 Werktagen zugestellt.
        </Typography>
        <router-link
          to="/screensaver"
          class="cart-paypal-page__button"
        >
          <CommonButton class="cart-transit-page__button">
            Bestellung abschliessen
          </CommonButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CommonButton from '@/components/common-button.vue';
import Typography from '@/components/typography.vue';

export default {
  name: 'CartTransitPage',
  components: { CommonButton, Typography },
  computed: {
    code() { return this.$store.state.cart.cart.cartCode; },
  },
};
</script>

<style lang="scss">
.cart-transit-page {
  &__heading-section {
    margin-top: 164px;
    grid-column: span 8;
  }

  &__heading {
    height: 220px;
  }

  &__orderstep-number {
    margin-top: 129px;
    align-items: baseline;
    display: flex;
    height: 50px;

    &::before {
      content: " ";
      width: 60px;
      border-bottom: 4px solid #e20074;
      margin-right: 10px;
    }
  }

  &__orderstep {
    margin-top: 129px;
    grid-column: 2 / span 7;
  }

  &__order-code-line {
    margin-top: 37px;
  }

  &__button {
    margin-top: 129px;
  }
}
</style>
