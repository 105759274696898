<template>
  <div
    class="filter-accordion-item"
    :class="containerClass"
  >
    <div class="filter-accordion-item__header">
      <Typography
        variant="flow-text-title"
        class="filter-accordion-item__label"
      >
        {{ label }}
      </Typography>
      <Typography
        variant="filter-selected"
        class="filter-accordion-item__selected-filter"
      >
        {{ selectedFilterString }}
      </Typography>
    </div>
    <div
      class="filter-accordion-item__slot"
    >
      <div class="filter-accordion-item__slot-content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import Typography from '@/components/typography.vue';

export default {
  name: 'FilterAccordionItem',
  components: {
    Typography,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    selectedFilter: {
      type: Array,
      default: null,
    },
  },
  computed: {
    containerClass() {
      return { 'filter-accordion-item--active': this.active };
    },
    selectedFilterString() {
      if (!this.selectedFilter) return null;
      return this.selectedFilter.join(', ');
    },
  },
};
</script>

<style lang="scss">
.filter-accordion-item {
  transform: translate3d(0, 0, 0);
  transition: background 0.5s;

  &__slot {
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    transition: height 1s;
    height: 0px;
    &-content {
      padding-top: 6px;
    }
  }

  &::before{
    transition: background 0.5s;
    content: '';
    height: 20px;
    display: block;
    background: linear-gradient(180deg, #E5E5E5 0%, #F9F9F9 50.92%, #F9F9F9 75.04%, #F2F2F2 100%);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 30px;
  }

  &__selected-filter {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label {
    transition: all 0.5s ease-in-out;
    font-size: 30px;
    line-height: 40px;
    white-space: nowrap;
    margin-right: 48px;
  }

  &--active {
    background-color: #FBFBFB;

    .filter-accordion-item__slot {
      height: 560px;
    }

    .filter-accordion-item__label {
      font-size: 40px;
      line-height: 50px;
    }

    &::before {
      content: '';
      height: 20px;
      display: block;
      background: linear-gradient(180deg, #E5E5E5 0%, #F9F9F9 50.92%, #FBFBFB 73.92%, #FBFBFB 100%);
    }

    &::after {
      content: '';
      height: 20px;
      display: block;
      background: linear-gradient(180deg, #FBFBFB 0%, #F9F9F9 50.92%, #F7F4F4 73.26%, #F3F3F3 100%);
    }

    & + .filter-accordion-item {
      &::before {
        content: none;
      }
    }
  }

  &:last-child:not(.filter-accordion-item--active):after {
    content: '';
    height: 20px;
    display: block;
    background: linear-gradient(180deg, #E5E5E5 0%, #F9F9F9 50.92%, #F9F9F9 75.04%, #F2F2F2 100%);
  }
}
</style>
