<template>
  <div class="search-offers-page">
    <Header
      :show-cart="false"
      :show-breadcrumb="false"
      :show-cancel="true"
    />
    <div class="search-offers-page__intro">
      <Typography
        variant="heading"
      >
        {{ headline }}
      </Typography>
      <div class="search-offers-page__button-container">
        <Typography
          class="search-offers-page__result-count"
          variant="title"
        >
          {{ searchText }}
          {{ offersCount }} Artikel
        </Typography>
        <CommonButton
          v-if="showFilter"
          class="search-offers-page__filter-button"
          @click="openFilterModal"
        >
          <div class="search-offers-page__filter-button-content">
            <FilterIcon class="search-offers-page__filter-icon" />
            <span>Filter</span>
          </div>
        </CommonButton>
      </div>
    </div>
    <FilterModal
      v-if="showFilter"
      ref="filterModal"
      filter-set-slug="search"
      path-prefix="/search/offers"
      @filter-update="updateOffersWithFilter"
    />
    <InfiniteShelf
      v-if="initialized && offerQueryParams"
      :offer-query-params="offerQueryParams"
      :number-of-boards="3"
      with-rebates
      :min-offers-on-shelf="3"
      @fetched-offers="onFetchedOffers"
      @updated-page="updatePage"
    />
    <Footer />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import { MESSAGES } from '@/store/loader';
import Typography from '@/components/typography.vue';
import InfiniteShelf from '@/components/infinite-shelf.vue';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import CommonButton from '@/components/common-button.vue';
import FilterModal from '@/components/filter-modal.vue';
import searchHelper from '@/lib/search-helper';
import FilterIcon from '@/assets/images/filter-button.svg';
import { serializeFilterParams } from '@/lib/filter-params';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';

const PAGE_SIZE = 30;

const STANDARD_SEARCH = {
  grouped: true,
  filter: {
    listed: true,
    types: ['TANGIBLE'],
    productAvailable: true,
  },
  sort: { direction: 'ASC', field: 'RELEVANCE' },
};

const DEFAULT_VARIABLES = {
  grouped: true,
  filter: {
    listed: true,
    types: ['TANGIBLE'],
    productAvailable: true,
  },
  pagination: {
    size: PAGE_SIZE,
  },
};

function searchGoliathWithTerm(searchTerm) {
  return { search: `${searchTerm}*` };
}

function prefabricatedSearch(nameOfSearch) {
  // TODO this is most probably wrong, ask Tobias Casper what the correct query would be
  return searchGoliathWithTerm(nameOfSearch);
}

const PREFAB_SEARCHES = {
  rebate: 'some goliath query',
  new: 'some goliath query',
};

export default {
  name: 'SearchOffersPage',
  components: {
    Header,
    Footer,
    InfiniteShelf,
    Typography,
    CommonButton,
    FilterIcon,
    FilterModal,
  },
  data() {
    return {
      initialized: false,
      searchType: undefined,
      sanitizedTerm: undefined,
      term: undefined,
      prefab: undefined,
      search: undefined,
      page: 1,
      filterVariables: null,
      offersCount: 0,
    };
  },
  computed: {
    offerQueryParams() {
      const variablesWithDefaults = mergeFilterVariables(
        this.filterVariables,
        this.searchParams,
        DEFAULT_VARIABLES,
      );
      return mergeFilterVariables(
        variablesWithDefaults,
        { pagination: { page: parseInt(this.$route.query.page || '1', 10) } },
      );
    },
    showFilter() {
      return !!this.term;
    },
    headline() {
      if (this.prefab === 'rebate') return 'Angebote';
      if (this.prefab === 'new') return 'Neuheiten';
      return 'Ihr Sucherergebnis';
    },
    searchText() {
      if (this.prefab || !this.term) return null;
      const sanitizedTerm = this.term.replace(/\*/g, '');
      return `für ’${sanitizedTerm}’.`;
    },
    pagination() {
      return { page: this.page, size: PAGE_SIZE };
    },
    searchParams() {
      return Object.assign(
        cloneDeep(STANDARD_SEARCH),
        { pagination: this.pagination },
        this.search,
      );
    },
  },
  watch: {
    $route() {
      this.parseRouteQuery();
      this.setOffersFromRoute();
    },
  },
  mounted() {
    this.showMessage(MESSAGES.LOADING_PRODUCTS);
    this.parseRouteQuery();
    if (!this.initialized) return;
    this.setOffersFromRoute();
  },
  methods: {
    ...mapActions({
      removeMessage: 'loader/removeMessage',
      showMessage: 'loader/showMessage',
    }),
    onFetchedOffers(offers) {
      this.removeMessage(MESSAGES.LOADING_PRODUCTS);
      this.offersCount = offers.pagination.totalCount;
    },
    updatePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    updateOffersWithFilter(filterParams) {
      this.filterVariables = filterParams;
      const mergedVariables = mergeFilterVariables(
        this.searchParams,
        this.filterVariables,
      );
      this.$router.push(serializeFilterParams(mergedVariables));
    },
    setOffersFromRoute() {
      if (this.prefab === 'rebate') {
        this.searchParams.search = undefined;
        this.searchParams.filter = { ...STANDARD_SEARCH.filter, discounted: true };
      }
    },
    openFilterModal() {
      this.$refs.filterModal.open();
    },
    parseRouteQuery() {
      this.searchType = undefined;
      if (this.$route.query.term) {
        // page called with ?term=whatever
        this.initializeTermSearch();
      } else if (this.$route.query.prefab) {
        // page called with ?prefab=whatever
        this.initializePrefabricatedSearch();
      }
      if (!this.initialized) {
        // either the page was called without ?term= and ?prefab=
        // or one of the .initialize*Search Methods failed
        this.$router.push({ name: 'error' });
      }
    },
    initializeTermSearch() {
      const rawTerm = this.$route.query.term;
      this.sanitizedTerm = searchHelper.sanitizeTerm(rawTerm);
      this.term = searchHelper.goliathifySearchTerm(this.sanitizedTerm);
      this.searchType = 'query';
      this.search = searchGoliathWithTerm(this.term);
      this.initialized = true;
    },
    initializePrefabricatedSearch() {
      this.prefab = this.$route.query.prefab;
      this.searchType = 'prefab';

      if (!PREFAB_SEARCHES[this.prefab]) return;

      this.search = prefabricatedSearch(this.prefab);
      this.initialized = true;
    },
  },
};
</script>

<style lang="scss">
.search-offers-page {
  &__intro {
    height: 240px;
    padding: 0 30px;
  }

  &__button-container {
    padding: 0 240px 0 0;
    position: relative;
  }

  &__result-count {
    display: block;
    padding: 32px 0;
  }

  &__filter-button {
    background-color: #fff;
    border-radius: 6px;
    border: 2px #000 solid;
    color: #000;
    position: absolute;
    right: 0;
    top: 14px;
    z-index: 2;
  }

  &__filter-button-content {
    align-items: center;
    display: flex;
  }

  &__filter-icon {
    margin-right: 12px;
  }
}
</style>
