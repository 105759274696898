<template>
  <div class="category-offer-list">
    <Header />
    <div class="category-offer-list__intro">
      <Typography
        :variant="typographyVariant"
        class="category-offer-list__title"
        v-html="label"
      />
      <div class="category-offer-list__button-container">
        <SortDropdown
          :offers-count="offersCount"
          class="category-offer-list__sort-dropdown"
          @change="handleSortUpdate"
        />
        <CommonButton
          v-if="offersAvailable"
          class="category-offer-list__filter-button"
          @click="openFilterModal"
        >
          <div class="category-offer-list__filter-button-content">
            <FilterIcon class="category-offer-list__filter-icon" />
            <span>Filter</span>
          </div>
        </CommonButton>
      </div>
    </div>
    <FilterModal
      ref="filterModal"
      :filter-set-slug="categorySlug"
      :category-name="label"
      path-prefix="/categories"
      @filter-update="updateOffersWithFilter"
    />
    <div class="infinite-shelf-container">
      <InfiniteShelf
        v-if="offerQueryParams"
        :offer-query-params="offerQueryParams"
        :number-of-boards="3"
        with-rebates
        :min-offers-on-shelf="3"
        @fetched-offers="onFetchedOffers"
        @updated-page="updatePage"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { mapActions, mapState } from 'vuex';
import Header from '@/components/header.vue';
import Typography from '@/components/typography.vue';
import InfiniteShelf from '@/components/infinite-shelf.vue';
import SortDropdown from '@/components/sort-dropdown.vue';
import FilterModal from '@/components/filter-modal.vue';
import Footer from '@/components/footer.vue';
import { parseFilterParams, serializeFilterParams } from '@/lib/filter-params';
import FilterIcon from '@/assets/images/filter-button.svg';
import CommonButton from '@/components/common-button.vue';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';
import { MESSAGES } from '@/store/loader';
import actions from '@/store/actions';
import { flattenCategories } from '@/lib/shelves-helper';

const PATH_PREFIX = '/categories/';

const DEFAULT_VARIABLES = {
  grouped: true,
  filter: {
    listed: true,
    types: ['TANGIBLE'],
    productAvailable: true,
  },
  pagination: {
    size: 30,
  },
};

export default {
  name: 'CategoryOfferListPage',
  components: {
    Header,
    InfiniteShelf,
    SortDropdown,
    Typography,
    Footer,
    FilterModal,
    FilterIcon,
    CommonButton,
  },
  data() {
    return {
      offersCount: 0,
    };
  },
  computed: {
    ...mapState({ categories: (state) => state.shelves.categories }),
    offersAvailable() {
      return this.offersCount > 0;
    },
    breadcrumbItems() {
      const items = [{ label: 'Home', path: '/start' }];

      if (!this.category) return items;

      items.push({ label: this.category.mainCategory.label, path: this.category.mainCategory.link });

      if (!this.category.mainCategory.items) return items;

      items.push({ label: this.category.label, path: this.category.link });

      return items;
    },
    category() {
      if (!this.$route || !this.categories) return null;
      const variables = parseFilterParams(this.$route);
      const categoryPath = variables.filter.productCategoryPaths[0];
      const prefixedCategoryPath = PATH_PREFIX + categoryPath;
      // flatten all available category paths from our category hierachy
      const flattenedCategoryPaths = flattenCategories(Object.values(this.categories));
      // match current path with the flattened category path from our category hierachy
      return flattenedCategoryPaths.find((categoryEntry) => categoryEntry.link === prefixedCategoryPath);
    },
    categorySlug() {
      if (!this.category) return null;
      return this.category.mainCategory.slug;
    },
    label() {
      if (!this.category) return null;
      return this.category.label;
    },
    typographyVariant() {
      if (this.label) {
        if (this.label.length > 25) return 'pre-heading';
        if (this.label.length > 15) return 'heading';
      }
      return 'heading-large';
    },
    filterVariables() {
      return parseFilterParams(this.$route);
    },
    offerQueryParams() {
      const variablesWithDefaults = mergeFilterVariables(
        this.filterVariables,
        DEFAULT_VARIABLES,
      );
      return mergeFilterVariables(
        variablesWithDefaults,
        { pagination: { page: parseInt(this.$route.query.page || '1', 10) } },
      );
    },

  },
  watch: {
    breadcrumbItems: {
      handler(value) {
        this.$store.dispatch(actions.UPDATE_BREADCRUMB, value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.showMessage(MESSAGES.LOADING_PRODUCTS);
  },
  methods: {
    ...mapActions({
      removeMessage: 'loader/removeMessage',
      showMessage: 'loader/showMessage',
    }),
    onFetchedOffers(offers) {
      this.removeMessage(MESSAGES.LOADING_PRODUCTS);
      this.offersCount = offers.pagination.totalCount;
    },
    openFilterModal() {
      this.$refs.filterModal.open();
    },
    async handleSortUpdate(sortParam) {
      const updatedVariables = mergeFilterVariables(
        this.filterVariables,
        {
          sort: {
            direction: sortParam.value.direction,
            field: sortParam.value.field,
          },
          pathPrefix: '/categories',
        },
      );
      this.updateOffersWithFilter(updatedVariables);
    },
    updateOffersWithFilter(filterParams) {
      const oldFilterParams = this.filterVariables;
      this.$router.push(serializeFilterParams(filterParams));
      if (!isEqual(oldFilterParams, this.filterVariables)) {
        this.showMessage(MESSAGES.LOADING_PRODUCTS);
      }
    },
    updatePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.category-offer-list {

  &__intro {
    padding: 0 30px;
    height: 240px;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button-container {
    padding: 0 240px 0 0;
    position: relative;
  }

  &__filter-button {
    background-color: #fff;
    border-radius: 6px;
    border: 2px #000 solid;
    color: #000;
    z-index: 2;
    position: absolute;
    top: 14px;
    right: 0;
  }

  &__filter-button-content {
    align-items: center;
    display: flex;
  }

  &__filter-icon {
    margin-right: 12px;
  }
}

.infinite-shelf-container {
  padding: 0 30px;
}
</style>
