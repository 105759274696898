<template>
  <div
    v-if="ready"
    class="cart-select-payment-page"
  >
    <Header
      :show-breadcrumb="false"
      :show-cart="false"
    />
    <div class="page-grid">
      <Typography
        variant="heading-large"
        class="cart-select-payment-page__heading"
      >
        Bestellung
      </Typography>
      <Typography
        variant="subheading"
        class="cart-select-payment-page__subheading"
      >
        Wie wollen Sie bezahlen?
      </Typography>
      <div class="cart-select-payment-page__payment-option">
        <div class="cart-select-payment-page__underscore" />
        <div>
          <Typography
            variant="flow-text-title"
            class="cart-select-payment-page__option-title"
          >
            An der Kasse
          </Typography>
          <Typography
            variant="flow-text"
            class="cart-select-payment-page__option-text"
          >
            Sie bezahlen ganz einfach bar oder mit Karte an unserer Kasse hier im Geschäft.
          </Typography>
          <CommonButton
            class="cart-select-payment-page__button"
            @click="transferCart"
          >
            An der Kasse bezahlen
          </CommonButton>
        </div>
      </div>
      <div class="cart-select-payment-page__icon">
        <CheckoutIcon />
      </div>
      <div
        v-if="showPaypalOption"
        class="cart-select-payment-page__payment-option"
      >
        <div class="cart-select-payment-page__underscore" />
        <div>
          <Typography
            variant="flow-text-title"
            class="cart-select-payment-page__option-title"
          >
            Per Smartphone mit PayPal
          </Typography>
          <Typography
            variant="flow-text"
            class="cart-select-payment-page__option-text"
          >
            Bezahlen Sie noch schneller mit ihrem Paypal-Konto per App oder auf der Website.
          </Typography>
          <router-link to="/cart/paypal">
            <CommonButton class="cart-select-payment-page__button">
              Mit Paypal bezahlen
            </CommonButton>
          </router-link>
        </div>
      </div>
      <div
        v-if="showPaypalOption"
        class="cart-select-payment-page__icon"
      >
        <PaypalIcon />
      </div>
      <Footer class="cart-select-payment-page__footer" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import features from '@/lib/features';
import CheckoutIcon from '@/assets/images/checkout-icon.svg';
import PaypalIcon from '@/assets/images/paypal-icon.svg';
import Header from '@/components/header.vue';
import CommonButton from '@/components/common-button.vue';
import Typography from '@/components/typography.vue';
import Footer from '@/components/footer.vue';
import actions from '@/store/actions';

export default {
  name: 'CartSelectPaymentPage',
  components: {
    Header,
    CommonButton,
    Footer,
    CheckoutIcon,
    PaypalIcon,
    Typography,
  },
  data() {
    return {
      ready: false,
      showPaypalOption: false,
    };
  },
  computed: {
    ...mapState({
      vp: (state) => state.session.vp,
    }),
  },
  mounted() {
    this.setup();
  },
  methods: {
    async setup() {
      const whitelist = (await axios.get('/api/paypal-vp-whitelist')).data;

      this.showPaypalOption = features.vsPaypal.enabled && whitelist.includes(`shop;vp=${this.vp}`);
      this.ready = true;
    },
    async transferCart() {
      await this.$store.dispatch(actions.TRANSFER_CART);
      this.$router.push('/cart/transit');
    },
  },
};
</script>

<style lang="scss">
.cart-select-payment-page {
  &__button {
    margin-top: 50px;
  }

  &__heading {
    margin-top: 84px;
    grid-column: span 8;
  }

  &__subheading {
    grid-column: span 8;
    margin-top: 16px;
  }

  &__payment-option {
    // placement
    margin-top: 166px;
    grid-column: span 5;
    // self
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 13px;
  }

  &__underscore {
    width: 100%;
    height: 40px;
    border-bottom: 4px solid #000;
  }

  &__icon {
    grid-column: span 3;
    display: grid;
    margin-top: 166px;
    align-content: center;
    justify-items: center;
  }

  &__option-text {
    margin-top: 10px;
  }

  &__footer {
    grid-column: 1/ -1;
    margin-top: 237px;
  }
}
</style>
