<template>
  <div class="variant-selector-color">
    <VariantSelectorBasic
      :variants="variants"
      :horizontal="horizontal"
      v-on="$listeners"
    >
      <template #default="scope">
        <div
          class="variant-selector-color__color"
          :style="style(scope.variant.color)"
          :title="scope.variant.name"
        />
      </template>
    </VariantSelectorBasic>
  </div>
</template>

<script>
import VariantSelectorBasic from './variant-selector-basic.vue';

export default {
  name: 'VariantSelectorColor',
  components: { VariantSelectorBasic },
  props: {
    variants: { type: Array, default: () => [] },
    horizontal: { type: Boolean },
  },
  methods: {
    style(color) {
      if (color?.colorful) {
        return {
          backgroundImage: 'linear-gradient(90deg, #FF8900 0%, #E20074 42.62%, #C31D8B 60.79%, #5484DE 100%)',
        };
      }
      if (!color?.code) return {};
      if (['#fff', '#ffffff'].includes(color.code.toLowerCase()) || color.name === 'Weiß') {
        return {
          border: '1px solid #C9C9C9',
          backgroundColor: color.code,
        };
      }
      return { backgroundColor: color.code };
    },
  },
};
</script>

<style lang="scss" scoped>
.variant-selector-color {
  &__color {
    display: block;
    height: 20px;
    width: 100px;
  }
}
</style>
