<template>
  <div class="teufel-start">
    <TeufelLogo
      width="327"
      height="121"
      class="teufel-start__logo"
    />
    <Typography
      ref="heading"
      variant="heading"
      class="teufel-start__heading"
    >
      Neuer Sound seit 1979
    </Typography>
    <div class="teufel-start__video-container">
      <VideoElement
        v-if="videoSrc"
        :is-active="videoPlaying"
        class="teufel-start__video"
        :src="videoSrc"
        :loop="true"
      />
      <GoliathImage
        alt="label"
        path="/content/content-images/virtual-shelf/teufel/c708db9bd78240fcdabdfeed20fd917f/produktbundle-startscreen@2x.webp"
        class="teufel-start__product-bundle"
      />
    </div>
    <div class="teufel-start__cta-container">
      <Typography
        ref="text"
        variant="subheading"
        class="teufel-start__cta-text-wrapper"
      >
        <div class="teufel-start__swipe-anim" />
        <span class="teufel-start__cta-text">Wische um mehr zu erfahren</span>
      </Typography>
      <CommonButton
        class="teufel-start__cta-button"
        @click="scrollToProducts"
      >
        Zu den Produkten
      </CommonButton>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/typography.vue';
import TeufelLogo from '@/assets/images/teufel-logo-grey.svg';
import VideoElement from '@/components/video-element.vue';
import CommonButton from '@/components/common-button.vue';
import GoliathImage from '@/components/goliath-image.vue';

export default {
  name: 'TeufelStart',
  components: {
    Typography,
    TeufelLogo,
    VideoElement,
    CommonButton,
    GoliathImage,
  },
  data() {
    return {
      videoPlaying: false,
    };
  },
  computed: {
    videoSrc() {
      return '/content/content-videos/virtual-shelf/90135c2ab9cc87c48063fb38a88d5e53/teufel-trailer.webm';
    },
  },
  mounted() {
    this.videoPlaying = true;
  },
  methods: {
    scrollToProducts() {
      const container = document.querySelector('.teufel__container');
      const target = document.querySelector('.teufel-category-shelf');
      const bounds = target.getBoundingClientRect();
      container.scroll({ behavior: 'smooth', left: bounds.left });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.teufel-start {
  display: flex;
  flex-direction: column;
  width: 1080px;

  &__logo {
    margin: 60px 70px 24px;
  }

  &__heading {
    display: inline-block;
    font-weight: 800;
    text-transform: none;
    margin-left: 70px;
    margin-bottom: 60px;
  }

  &__video-container {
    position: relative;
  }

  &__video {
    margin-left: 70px;
    margin-bottom: 440px;
    width: 1010px;
    overflow: hidden;
  }

  &__product-bundle {
    position: absolute;
    bottom: 45px;
    right: 55px;
    width: 525px;
  }

  &__cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__cta-button {
    margin: 30px;
  }

  &__cta-text-wrapper {
    display: block;
    position: relative;
  }

  &__cta-text {
    position: relative;
  }

  &__swipe-anim {
    position: absolute;
    top: -13px;
    left: 0;
    background-color: #e20074;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    z-index: 0;
    animation: swipeAnimation 5s ease 0s infinite;
    transform-origin: center;
  }

}

@keyframes swipeAnimation {
  0% {
    left: 100%;
    transform: scale(0);
  }

  5% {
    left: 100%;
    transform: scale(1);
  }

  25% {
    transform: scale(1, 0.66);
  }

  45% {
    left: -40px;
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}
</style>
