<template>
  <div
    ref="cartRecommendations"
    class="cart-recommendations"
  >
    <ShelfEntry
      class="cart-recommendations__shelf"
      :show-title="false"
      variant="recommendations"
    >
      <OfferOnShelf
        v-for="(recommendation, index) in recommendations"
        :key="`offerRecommendation${index}`"
        ref="offerRecommendation"
        v-bind="recommendation"
      />
    </Shelfentry>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import OfferOnShelf from '@/components/offer-on-shelf.vue';
import ShelfEntry from '@/components/shelf-entry.vue';

gsap.registerPlugin(Draggable);

const tl = gsap.timeline();
const MAX_SCREEN_WIDTH = 1080;
const MINIMUM_DRAG_MOVEMENT = 10;
const PADDING = 80;

export default {
  name: 'CartRecommendations',
  components: {
    ShelfEntry,
    OfferOnShelf,
  },
  props: {
    recommendations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
    };
  },
  mounted() {
    this.triggerInitialSlideAnimation();
    this.createDraggable();
  },
  methods: {
    setBounds() {
      this.draggable[0].applyBounds({
        minX:
          MAX_SCREEN_WIDTH
          - this.$refs.cartRecommendations.scrollWidth
          - PADDING,
        maxX: 0,
      });
    },
    createDraggable() {
      this.draggable = Draggable.create('.cart-recommendations', {
        type: 'x',
        onDrag: this.setBounds,
        edgeResistance: 0.7,
        inertia: true,
        minimumMovement: this.minimumDragMovement,
        zIndexBoost: false,
      });
    },
    triggerInitialSlideAnimation() {
      tl.from('.cart-recommendations__shelf', {
        x: '100%',
        opacity: 0,
        duration: 1,
      });
    },
  },
};
</script>

<style lang="scss">
.cart-recommendations {
  display: flex;
}
</style>
