<template>
  <div class="screensaver-category-tile">
    <TeufelLogo
      v-if="isTeufel"
      class="screensaver-category-tile__teufel-logo"
      fill="#fff"
    />
    <Typography
      v-else
      class="screensaver-category-tile__label screensaver-category-tile__label--top"
      variant="hint"
    >
      {{ label }}
    </Typography>

    <GoliathImage
      :alt="label"
      :path="imageSrc"
      class="screensaver-category-tile__image"
    />
    <router-link
      :to="path"
      class="screensaver-category-tile__link"
    />
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image.vue';
import Typography from '@/components/typography.vue';
import TeufelLogo from '@/assets/images/teufel-logo-red.svg';

export default {
  name: 'ScreensaverCategoryTile',
  components: {
    TeufelLogo,
    GoliathImage,
    Typography,
  },
  props: {
    // `/offer/${this.slug}`
    path: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    isTeufel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.screensaver-category-tile {
  position: relative;

  a, a:visited {
    text-decoration: none;
    color: transparent;
  }

  &__teufel-logo {
    position: absolute;
    bottom: 24px;
    right: 16px;
    width: 132px;
    height: 49px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    a, a:visited {
      text-decoration: none;
      color: transparent;
    }
  }

  &__image {
    width: 460px;
  }

  &__label {
    font-weight: 700;
    color: #FFFFFF;
    font-size: 31px;
    font-weight: 900;
    letter-spacing: 0.31px;
    line-height: 36px;

    &--top {
      position: absolute;
      top: 16px;
      left: 16px;
      text-transform: uppercase;
    }
  }
}
</style>
