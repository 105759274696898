<template>
  <div class="sort-dropdown">
    <template v-if="offersAvailable">
      <div
        class="sort-dropdown__label"
        :class="labelClasses"
        @click="toggle"
      >
        {{ offersCount }} Artikel sortieren nach:&nbsp;
      </div>
      <Dropdown
        :show-all-options="showAllOptions"
        :value="currentSortOption.value"
        :options="options"
        @change="onChange"
        @nochange="toggle"
      />
    </template>
    <template v-else>
      <div class="sort-dropdown__label sort-dropdown__label--visible">
        {{ offersCount }} Artikel
      </div>
    </template>
  </div>
</template>

<script>
import Dismissable from '@/mixins/dismissable';
import Dropdown from '@/components/dropdown.vue';

const DEFAULT_OPTIONS = [
  {
    label: 'Beliebteste',
    value: { field: 'RECOMMENDATION', direction: 'ASC' },
    sort: '',
    left: '0',
    transitionDelay: '0s',
  },
  {
    label: 'Preis aufsteigend',
    value: { field: 'ONETIME_PRICE', direction: 'ASC' },
    sort: 'priceAsc',
    left: '145px',
    transitionDelay: '0.15s',
  },
  {
    label: 'Preis absteigend',
    value: { field: 'ONETIME_PRICE', direction: 'DESC' },
    sort: 'priceDesc',
    left: '354px',
    transitionDelay: '0.3s',
  },
  {
    label: 'Neu eingetroffen',
    value: { field: 'INSERTED_AT', direction: 'DESC' },
    sort: 'new',
    left: '557px',
    transitionDelay: '0.45s',
  },
];

const DEFAULT_VALUE = DEFAULT_OPTIONS[0];

export default {
  name: 'SortDropdown',
  components: {
    Dropdown,
  },
  mixins: [Dismissable],
  props: {
    offersCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedLabels: DEFAULT_VALUE,
      showAllOptions: false,
    };
  },
  computed: {
    currentSortOption() {
      if (this.$route.query) {
        return this.options.find((option) => (option.sort === this.$route.query.sort)) || this.selectedLabels;
      }
      return this.selectedLabels;
    },
    options() {
      return DEFAULT_OPTIONS;
    },
    offersAvailable() {
      return this.offersCount > 0;
    },
    labelClasses() {
      const classes = [];
      if (!this.showAllOptions) classes.push('sort-dropdown__label--visible');
      return classes;
    },
  },
  methods: {
    onChange(value) {
      this.selectedLabels = value;
      this.$emit('change', value);
      this.showAllOptions = false;
    },
    toggle() {
      this.showAllOptions = !this.showAllOptions;
    },
  },
};
</script>

<style lang="scss">
.sort-dropdown {
  display: inline-flex;
  height: 100px;
  position: relative;
  max-width: 100%;
  padding: 32px 0;

  &__label {
    flex: 0 0 0;
    font-size: 30px;
    line-height: 34px;
    opacity: 0;
    overflow: hidden;
    transition: flex 0.3s linear, opacity 0.15s linear;
    white-space: nowrap;

    &--visible {
      flex: 1 0 0;
      opacity: 1;
      transition: flex 0.3s linear, opacity 0.15s linear 0.3s;
    }
  }
}
</style>
