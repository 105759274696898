<template>
  <div
    v-show="isActive"
    class="timeout-modal"
    :class="containerClass"
  >
    <TimerBar
      ref="timer"
      :duration="timerBarTime"
    />
    <div class="timeout-modal__content">
      <template v-if="hasFullHeadline">
        <div class="timeout-modal__heading-part-1">
          <Typography variant="heading-large-magenta">
            Sind Sie
          </Typography>
        </div>
        <div class="timeout-modal__heading-part-2">
          <Typography variant="heading-gigantic-magenta">
            noch da?
          </Typography>
        </div>
      </template>
      <template v-else>
        <div class="timeout-modal__heading-part-1">
          <Typography variant="heading-large-magenta">
            Abbrechen
          </Typography>
        </div>
      </template>
      <div class="timeout-modal__explanation">
        <Typography variant="timeout-content">
          Ihre Sitzung wird in {{ internalSecondsLeft.toFixed() }} Sekunden aufgelöst.<br>
          Tippen Sie, um Ihren Einkauf fortzusetzen.
        </Typography>
      </div>
      <div class="timeout-modal__countdown">
        <Typography variant="timeout-counter">
          {{ internalSecondsLeft.toFixed() }}
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Typography from '@/components/typography.vue';
import TimerBar from '@/components/timer-bar.vue';

export default {
  name: 'TimeoutModal',
  components: { Typography, TimerBar },
  props: {
    showModalAt: {
      type: Number,
      required: true,
    },
    fullCycleTime: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countdownInterval: null,
      internalTimeLeft: this.timeLeft,
      timerBarTime: 0,
      isActive: false,
    };
  },
  computed: {
    containerClass() {
      if (!this.variant) return null;
      return `timeout-modal--${this.variant}`;
    },
    hasFullHeadline() {
      return this.variant !== 'small';
    },
    internalSecondsLeft() {
      return (this.internalTimeLeft / 1000);
    },
    ...mapState({
      lastTouchedAt: (state) => state.timeout.lastTouchedAt,
      timeLeft: (state) => state.timeout.timeLeft,
    }),
  },
  watch: {
    timeLeft: {
      async handler() {
        this.clearInterval();
        await this.updateTime();
        if (!this.countdownInterval) {
          this.countdownInterval = setInterval(() => this.updateTime(), 1000);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      this.clearInterval();
    }
  },
  methods: {
    clearInterval() {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    },
    async becomesActive() {
      this.$emit('toggles-active', true);
      this.isActive = true;
      if (!this.timerBarTime) this.timerBarTime = this.internalTimeLeft;
      if (!this.$refs.timer) return;
      await this.$nextTick();
      this.$refs.timer.start();
    },
    becomesInactive() {
      this.$emit('toggles-active', false);
      this.isActive = false;
      this.timerBarTime = 0;
      if (!this.$refs.timer) return;
      this.$refs.timer.updateProgress(0);
    },
    async updateTime() {
      this.internalTimeLeft = this.timeLeft
        - (Date.now() - this.lastTouchedAt);

      if (this.internalTimeLeft > 0
        && this.internalTimeLeft <= this.showModalAt
        && !this.isActive) {
        await this.becomesActive();
      } else if (this.internalTimeLeft <= 0) {
        this.becomesInactive();
        // time ran out
        this.$emit('time-ran-out');
      } else if (this.internalTimeLeft > this.showModalAt && this.isActive) {
        this.becomesInactive();
      }
    },
  },
};
</script>

<style lang="scss">
.timeout-modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(242, 242, 242, 0.7);
  padding: 110px 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    padding: 72px;
    padding-right: 60px;
    z-index: 200;
    background: #ffffff;
    box-shadow: 1px 11px 25px 1px rgba(0, 0, 0, 0.31);
    height: 100%;
    width: 100%;
  }

  &__heading-part-1 {
    padding-top: 147px;
  }

  &__heading-part-2 {
    padding-top: 39px;
    padding-left: 60px;
  }

  &__explanation {
    padding-top: 99px;
  }

  &__countdown {
    padding-top: 423px;
    text-align: center;
  }

  &--small {
    .timeout-modal__content {
      height: auto;
      padding: 96px 72px;
      padding-right: 60px;
    }
    .timeout-modal__countdown {
      display: none;
    }
    .timeout-modal__heading-part-1 {
      padding: 0;
    }
  }
}
</style>
