<template>
  <FilterAccordionItem
    :label="question"
    class="question-and-answer"
    :active="active"
    @click.native="toggle"
  >
    <Typography
      ref="answer"
      class="question-and-answer__answer"
      variant="answer"
      v-html="answer"
    />
  </FilterAccordionItem>
</template>

<script>
import FilterAccordionItem from '@/components/filter-accordion-item.vue';
import Typography from '@/components/typography.vue';

export default {
  name: 'QuestionAndAnswer',
  components: {
    FilterAccordionItem,
    Typography,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style lang="scss">
.question-and-answer {
  &__answer {
    margin: 0 30px;
  }
}
</style>
