import { attachmentThumbUrl } from '@/lib/attachment-route';
import { getSimplifiedPrices, getSimplifiedColorAlternatives } from './goliath/simplify-offer';

/* Called when a list generator gets an entry it
 * can not convert into a real ItemOnShelf.
 * Should help debugging.
 */
function UnknownEntry(label, info = {}) {
  return {
    ...info,
    label,
    component: 'UnknownOnShelf',
    imageSrc: '/content/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png',
  };
}

/* takes an object and some config params and
 * creates a CategoryOnShelf from it
 * - knownTopCategories triggers the link going to /categories/ or /sub-categories/
 * - slugPrefix is added to the slug, keeps the original JSON readable
 */
function categoryOnShelf(entry, { knownTopCategories = [], slugPrefix = '' }) {
  if (!entry.slug) return UnknownEntry('categories must have a .slug');
  if (!entry.label) return UnknownEntry('categories must have a .label');
  const slug = `${slugPrefix}${entry.slug}`;
  const linkPrefix = knownTopCategories.includes(entry.slug) ? '/sub-categories/' : '/categories/';
  const link = `${linkPrefix}${slug}`;

  return {
    ...entry,
    component: 'ClayOnShelf',
    imageSrc: `/content/content-images/virtual-shelf/clays/${entry.image || entry.slug}.png`,
    animate: true,
    link,
  };
}

function searchOnShelf(entry) {
  if (!entry.prefab) return UnknownEntry('searches must have a .prefab');
  if (!entry.label) return UnknownEntry('searches must have a .label');
  return {
    ...entry,
    component: 'ClayOnShelf',
    imageSrc: `/content/content-images/virtual-shelf/clays/search-${entry.prefab}.png`,
    animate: true,
    link: `/search/offers?prefab=${entry.prefab}`,
  };
}

function customLinkOnShelf(entry) {
  return {
    component: 'ClayOnShelf',
    animate: true,
    ...entry,
  };
}

function generateOfferOnShelf(entry) {
  const prices = getSimplifiedPrices(entry.prices);
  const colorAlternatives = getSimplifiedColorAlternatives(entry.productAlternatives);
  if (!prices.default) return UnknownEntry('offer must have default price');
  const image = attachmentThumbUrl(entry.teaserImage) || '/content/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png';
  const label = entry.product.design?.name || entry.product.family.name;
  return {
    component: 'OfferOnShelf',
    label,
    link: `/offer/${entry.product.slug}`,
    imageSrc: image,
    articleNumber: entry.product.articleNumber,
    prices,
    colorAlternatives,
    stock: entry.product.stock.amount,
    rebate: entry.rebate,
    bundle: entry.product.bundle,
    productAlternatives: entry.productAlternatives,
    customizability: entry.product.customizability || 'NONE',
    product: entry.product,
  };
}

export function expandShelfEntry(entry, context) {
  if (typeof entry !== 'object' || Array.isArray(entry)) return UnknownEntry('Entry is not an object');
  if (!('type' in entry)) return UnknownEntry('Entry does not have a .type');
  switch (entry.type) {
    case 'category': return categoryOnShelf(entry, context);
    case 'search': return searchOnShelf(entry);
    case 'custom-link': return customLinkOnShelf(entry);
    default: return UnknownEntry(`Unknown .type '${entry.type}'`);
  }
}

const SCHEMA = 'http://schema.i22.de/virtual-shelf/shelf-contents.';
function verifySchema(schema) {
  if (schema.startsWith(SCHEMA)) return;
  throw new Error(`Schema must be ${SCHEMA}`);
}

export function generateItemsOnShelfFromJson(categories) {
  const shelves = {};
  const knownTopCategories = Object.keys(categories).filter((name) => name !== 'start');
  Object.keys(categories).forEach((shelfName) => {
    const entry = categories[shelfName];
    const { slugPrefix } = entry;
    if (shelfName === '$schema') {
      verifySchema(entry);
    } else {
      const context = {
        slugPrefix,
        knownTopCategories,
      };
      shelves[shelfName] = {
        title: entry.title,
        copy: entry.copy,
        items: entry.items?.map((item) => expandShelfEntry(item, context)),
      };
    }
  });
  return shelves;
}

export function generateOffersOnShelfFromGoliath(rawResponse) {
  return rawResponse.entries.map(generateOfferOnShelf);
}

export function generateRecommendationsOnShelfFromGoliath(offerRecommendations) {
  return offerRecommendations.map((offerRecommendation) => offerRecommendation
    .map(generateOfferOnShelf));
}

export function flattenCategories(categories, parentCategory) {
  let results = [];

  (categories || []).forEach((category) => {
    // skip "start"
    if (!category.label) return;
    let mainCategory;
    if (parentCategory) {
      mainCategory = parentCategory;
    } else {
      mainCategory = category;
    }

    results.push({
      label: category.label,
      link: category.link,
      mainCategory,
    });

    const childResults = flattenCategories(category.items, mainCategory);
    results = results.concat(childResults);
  });

  return results;
}
