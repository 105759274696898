const GOLIATH_PATH_PREFIX_REGEXP = /^\/public/;

function replacePath(path, remote = false) {
  if (!path) return '';

  return remote ? path : path.replace(GOLIATH_PATH_PREFIX_REGEXP, '/product-images');
}

function url(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return replacePath(attachment.url, true);
  return replacePath(attachment.path);
}

/**
 * @param {*} attachment LocalAttachment or RemoteAttachment from Goliath
 * @returns {string}
 */
function thumbImageUrl(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) {
    return replacePath(attachment.url, true);
  }
  return replacePath(attachment.thumbImagePath);
}

export { url as attachmentUrl, thumbImageUrl as attachmentThumbUrl, replacePath };

export default { url, thumbImageUrl, replacePath };
