<template>
  <div
    class="prices"
  >
    <div @click="priceClicked">
      <Price
        v-if="pricesData.was"
        v-bind="pricesData.was"
        :is-slashed="true"
        class="prices__was"
        :class="variantClass"
      />
      <Price
        v-if="pricesData.default"
        v-bind="pricesData.default"
        class="prices__default"
        :class="variantClass"
      />
    </div>

    <Footnote
      :footnote-text-array="legalText"
      class="prices__legal-text"
    />
    <Modal
      ref="footnote"
      class="prices__modal"
    >
      <div
        v-for="(text, index) in legalText"
        :key="`legaltext${index}`"
      >
        <div
          class="prices__modal-text"
          v-html="text"
        />
      </div>
    </Modal>
  </div>
</template>

<script>

const VALID_VARIANTS = ['small'];

export default {
  name: 'Prices',
  components: {
    Price: () => import('@/components/price.vue'),
    Modal: () => import('@/components/modal.vue'),
    Footnote: () => import('@/components/footnote.vue'),
  },
  props: {
    pricesData: {
      type: Object,
      required: true,
    },
    legalText: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: null,
      validator(value) {
        return VALID_VARIANTS.includes(value);
      },
    },
  },
  computed: {
    variantClass() {
      return this.variant ? `prices--${this.variant}` : null;
    },
  },
  methods: {
    priceClicked() {
      if (this.legalText.length > 0) {
        this.$refs.footnote.open();
      }
    },
  },
};
</script>

<style lang="scss">
.prices {
  display: inline-flex;
  position: relative;

  &__default {
    font-size: 40px;
    letter-spacing: 0.5px;
    line-height: 60px;
    align-self: baseline;
  }

  &__was {
    align-self: baseline;
    font-size: 25px;
    letter-spacing: 0.30px;
    line-height: 25px;
    margin-right: 20px;
  }

  &__legal-text {
    position: absolute;
    right: -22px;
  }

  &__modal {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__modal-text {
    padding-bottom: 40px;
  }

  &--small {
    font-size: 30px;
    line-height: 25px;
  }
}
</style>
