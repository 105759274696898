<template>
  <div class="start-page">
    <Header />
    <div class="start-page__intro">
      <Typography
        variant="heading-large"
        class="start-page__kicker"
      >
        Willkommen
      </Typography>
      <Typography variant="pre-heading">
        zu einem neuen Einkaufserlebnis
      </Typography>
    </div>
    <StaticShelf name="start" />
    <Footer />
  </div>
</template>

<script>
import ms from 'ms';
import { mapState, mapMutations } from 'vuex';
import StaticShelf from '@/components/static-shelf.vue';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import actions from '@/store/actions';
import Typography from '@/components/typography.vue';

export default {
  name: 'StartPage',
  components: {
    Header,
    Footer,
    StaticShelf,
    Typography,
  },
  computed: {
    ...mapState({
      cancelOverlaySwitchActive: (state) => state.cancelOverlaySwitch.active,
    }),
  },
  watch: {
    cancelOverlaySwitchActive: {
      handler(switchActive) {
        if (switchActive) {
          this.setCancelOverlayActive();
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch(actions.UPDATE_BREADCRUMB, [{ label: 'Home', path: '/start' }]);
    if (this.cancelOverlaySwitchActive) {
      this.setCancelOverlayActive();
    }
  },
  methods: {
    ...mapMutations({
      setTimeLeft: 'timeout/SET_TIME_LEFT',
      setLastTouchedAt: 'timeout/SET_LAST_TOUCHED_AT',
    }),
    setCancelOverlayActive() {
      this.setTimeLeft(ms('10s'));
      this.setLastTouchedAt(Date.now());
    },
  },
};
</script>

<style lang="scss">
.start-page {
  &__intro {
    height: 240px;
    padding: 35px 30px 0;
  }

  &__kicker {
    margin: 0 0 12px;
  }
}
</style>
