<template>
  <div class="teufel-category-shelf">
    <div class="category-offer-list__intro">
      <div class="category-offer-list__button-container">
        <SortDropdown
          :offers-count="offersCount"
          class="category-offer-list__sort-dropdown"
          @change="handleSortUpdate"
        />
      </div>
    </div>
    <div class="infinite-shelf-container">
      <InfiniteShelf
        v-if="offerQueryParams"
        :offer-query-params="offerQueryParams"
        :number-of-boards="3"
        with-rebates
        :min-offers-on-shelf="3"
        @fetched-offers="onFetchedOffers"
        @updated-page="updatePage"
      />
    </div>
  </div>
</template>

<script>
import InfiniteShelf from '@/components/infinite-shelf.vue';
import SortDropdown from '@/components/sort-dropdown.vue';

export default {
  name: 'TeufelCategoryShelf',
  components: {
    InfiniteShelf,
    SortDropdown,
  },
  data() {
    return {
      offersCount: 0,
      page: 1,
      sort: null,
    };
  },
  computed: {
    offerQueryParams() {
      return {
        grouped: true,
        filter: {
          listed: true,
          types: ['TANGIBLE'],
          productAvailable: true,
          brandSlugs: ['teufel'],
          productCategoryPaths: ['zubehoer'],
        },
        pagination: {
          size: 30,
          page: this.page,
        },
        sort: this.sort,
      };
    },
  },
  methods: {
    onFetchedOffers(offers) {
      this.offersCount = offers.pagination.totalCount;
    },
    async handleSortUpdate(sortParam) {
      this.sort = sortParam.value || null;
    },
    updatePage(page) {
      this.page = page || 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.teufel-category-shelf {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  overflow-x: clip;
}
</style>
