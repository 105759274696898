<template>
  <div
    ref="infiniteShelf"
    class="infinite-shelf"
    :style="styleParams"
  >
    <template
      v-for="(entries, index) in pages"
    >
      <TilePage
        ref="tilePage"
        :key="`tile-page-${index}`"
        class="infinite-shelf__tile-page"
        :page-id="parseInt(index, 10)"
        :entries="entries"
        :active="isActive(parseInt(index, 10))"
        :number-of-boards="numberOfBoards"
        :items-on-board="columnsOnPage(parseInt(index, 10))"
        :tile-width="tileWidth"
        :tile-height="tileHeight"
      />
    </template>
    <div class="infinite-shelf__shelf-board-container">
      <ShelfBoard
        v-for="n in numberOfBoards"
        :key="`infinite-shelf__shelf-board-${n}`"
        :class="`infinite-shelf__shelf-board infinite-shelf__shelf-board--${n}`"
        :style="shelfBoardStyles()"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep, omit, isEqual } from 'lodash';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import TilePage from '@/components/tile-page.vue';
import ShelfBoard from '@/components/shelf-board.vue';
import fetchOffers from '@/lib/goliath/offers';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';
import { generateOffersOnShelfFromGoliath } from '@/lib/shelves-helper';
import GetAsync from '@/mixins/get-async';

import Rebates from '@/lib/rebates';

gsap.registerPlugin(Draggable);

const MINIMUM_DRAG_MOVEMENT = 10;
const BOARD_PADDING = 50;
const TILE_SIZES = {
  m: { width: 650, height: 360 },
  l: { width: 832, height: 530 },
};
const QUERY_DEFAULTS = {
  grouped: true,
  filter: {
    listed: true,
    types: ['TANGIBLE'],
    productAvailable: true,
  },
  pagination: {
    page: 1,
    size: 30,
  },
};

function isEqualIgnoring(a, b, paths) {
  if (typeof path === 'string') {
    return isEqualIgnoring(a, b, [paths]);
  }

  return isEqual(omit(a, paths), omit(b, paths));
}

export default {
  components: {
    TilePage,
    ShelfBoard,
  },
  mixins: [
    GetAsync,
  ],
  props: {
    numberOfBoards: { type: Number, default: 3 },
    minOffersOnShelf: { type: Number, default: 0 },
    withRebates: { type: Boolean, default: false },
    tileSize: {
      type: String,
      default: 'm',
      validator: Object.prototype.hasOwnProperty.bind(TILE_SIZES),
    },
    offerQuery: { type: Function, default: fetchOffers },
    offerQueryParams: { type: Object, default: () => QUERY_DEFAULTS },
  },
  data() {
    return {
      boardPadding: BOARD_PADDING,
      fetchedMinPage: 0,
      fetchedMaxPage: 0,
      infiniteShelfWidth: null,
      minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
      draggableInstance: [],
      initialPage: null,
      activePage: null,
      totalQueryPages: null,
      totalQueryCount: null,
      rebates: null,
      pages: null,
    };
  },
  computed: {
    tileWidth() {
      return TILE_SIZES[this.tileSize].width;
    },
    tileHeight() {
      return TILE_SIZES[this.tileSize].height;
    },
    totalPages() {
      return this.totalQueryPages !== null
        ? Math.max(this.totalQueryPages, 1)
        : null;
    },
    totalCount() {
      if (this.totalQueryCount === null) return null;

      return this.totalQueryCount < this.minOffersOnShelf
        ? Math.min(this.totalQueryCount + 2, this.minOffersOnShelf)
        : this.totalQueryCount;
    },
    firstPage() {
      return 1;
    },
    lastPage() {
      return this.totalPages;
    },
    draggable() {
      return this.draggableInstance.length ? this.draggableInstance[0] : null;
    },
    fetchedEndRight() {
      return this.calculateEndOfPage(this.fetchedMaxPage);
    },
    fetchedEndLeft() {
      return this.calculateBeginningOfPage(this.fetchedMinPage);
    },
    rightLoadingThreshold() {
      return this.fetchedEndRight - this.loadingThreshold(this.fetchedMaxPage);
    },
    leftLoadingThreshold() {
      return this.fetchedEndLeft + this.loadingThreshold(this.fetchedMinPage);
    },
    dragPosition() {
      return -this.draggable.x || 0;
    },
    rightDragPosition() {
      return this.dragPosition + this.infiniteShelfWidth;
    },
    closeToRightEnd() {
      return this.dragPosition + this.infiniteShelfWidth > this.rightLoadingThreshold;
    },
    closeToLeftEnd() {
      return this.dragPosition < this.leftLoadingThreshold;
    },
    hasLoadedAllRight() {
      return this.lastPage && this.fetchedMaxPage >= this.lastPage;
    },
    hasLoadedAllLeft() {
      return this.fetchedMinPage <= this.firstPage;
    },
    calculatedActivePage() {
      return Math.max(Math.floor(this.dragPosition / this.pageWidth(1)) + 1, 1);
    },
    querySize() {
      return this.offerQueryParams.pagination.size;
    },
    boardWidth() {
      if (!this.totalPages) {
        return this.infiniteShelfWidth;
      }

      return Math.max(
        this.calculateEndOfPage(this.totalPages) + this.boardPadding,
        this.infiniteShelfWidth,
      );
    },
    offersOnActivePage() {
      return this.offersOnPage(this.activePage);
    },
    styleParams() {
      return `--tileHeight: ${this.tileHeight}px;
      --boardPadding: ${this.boardPadding}px;
      --shelfWidth: ${this.infiniteShelfWidth}px;`;
    },
  },
  watch: {
    offerQueryParams: {
      handler(newParams, oldParams) {
        if (!isEqualIgnoring(newParams, oldParams, 'pagination.page')) {
          this.reload();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initPageData();
  },
  mounted() {
    this.initShelf();
  },
  methods: {
    reload() {
      this.reset();
      this.addOffers(1);
    },
    reset() {
      this.scrollTo(0);
      this.activePage = 1;
      this.fetchedMinPage = 1;
      this.fetchedMaxPage = 1;
      this.totalQueryPages = null;
      this.totalQueryCount = null;
      this.pages = null;
    },
    calculateBeginningOfPage(page) {
      return this.boardPadding + (page - 1) * this.pageWidth(page - 1);
    },
    calculateCenterOfPage(page) {
      return this.calculateBeginningOfPage(page) + (this.pageWidth(page) / 2);
    },
    calculateEndOfPage(page) {
      return this.calculateBeginningOfPage(page) + (this.pageWidth(page));
    },
    pageWidth(page) {
      return this.columnsOnPage(page) * this.tileWidth;
    },
    columnsOnPage(page) {
      return Math.ceil(this.offersOnPage(page) / this.numberOfBoards);
    },
    offersOnPage(page) {
      if (!this.totalPages) return this.querySize; // we just assume that its right
      if (page > 0 && page < this.totalPages) return this.querySize;
      if (page === this.totalPages) {
        return this.totalCount % this.querySize || this.querySize;
      }
      return 0;
    },
    loadingThreshold(page) {
      return this.pageWidth(page) * 0.3;
    },
    createGraveyard() {
      const pages = {};
      for (let page = 1; page <= this.totalPages; page += 1) {
        pages[page] = Array(this.offersOnPage(page)).fill({});
      }
      this.pages = pages;
    },
    shelfBoardStyles() {
      return {
        width: `${this.boardWidth}px`,
      };
    },
    isActive(page) {
      const activePageBoundRight = Math.min(this.activePage + 1, this.totalPages || this.activePage);
      const activePageBoundLeft = Math.max(this.activePage - 1, 1);
      return activePageBoundLeft <= page && page <= activePageBoundRight;
    },
    validatePage(page) {
      return page >= 1 && (!this.totalQueryPages || page <= this.totalQueryPages);
    },
    async addOffers(page) {
      if (!this.validatePage(page)) return;
      let offers = await this.offerQuery(this.getOfferParams(page));
      if (!offers) {
        console.error('Failed to fetch offers.');
        return;
      }
      this.$emit('fetched-offers', offers);
      if (!this.totalQueryPages) {
        this.totalQueryPages = offers.pagination.totalPages;
        this.totalQueryCount = offers.pagination.totalCount;
        if (this.pages === null) this.createGraveyard();
        this.initDraggableBoundaries();
      }
      if (this.rebates) offers = await this.rebates.attachRebatesTo(offers);
      let simplifiedOffers = generateOffersOnShelfFromGoliath(offers);
      simplifiedOffers = await this.fillWithClays(simplifiedOffers);
      if (this.tileSize.includes('l')) simplifiedOffers = this.markOffersAsBig(simplifiedOffers);
      this.pages[page] = simplifiedOffers;
    },
    getOfferParams(page) {
      return [this.offerQueryParams, { pagination: { page } }]
        .reduce(mergeFilterVariables, QUERY_DEFAULTS);
    },
    initDraggableBoundaries() {
      const boundsMin = 0;
      const boundsMax = (this.boardWidth - this.infiniteShelfWidth) * -1;
      this.setBounds(boundsMin, boundsMax);
    },
    async fillWithClays(simplifiedOffers) {
      if (simplifiedOffers.length >= this.minOffersOnShelf || this.totalPages > 1) return simplifiedOffers;
      const items = cloneDeep(simplifiedOffers);
      if (items.length < this.minOffersOnShelf) items.push({ ...await this.getShelfItem('offersItem'), animate: false });
      if (items.length < this.minOffersOnShelf) items.push({ ...await this.getShelfItem('noveltyItem'), animate: false });
      return items;
    },
    async getShelfItem(item) {
      return this.getAsync(`$store.state.shelves.${item}`);
    },
    markOffersAsBig(simplifiedOffers) {
      return simplifiedOffers.map((offer) => ({ ...offer, isBig: true }));
    },
    setBounds(boundsMin, boundsMax) {
      if (!this.draggable) return;
      this.draggable.applyBounds({
        minX: boundsMin,
        maxX: boundsMax,
      });
    },
    dragHandler() {
      if (!this.draggable) return;
      const dragDirection = this.draggable.getDirection();
      const dragTowardsEndRight = dragDirection === 'left';
      const dragTowardsEndLeft = dragDirection === 'right';
      this.updateActivePage();
      if (dragTowardsEndRight && this.closeToRightEnd && !this.hasLoadedAllRight) {
      // We might be able do improve performance by loading pages that are not
      // active so that they are hidden, when the content is replaced.
        this.addOffers(this.fetchedMaxPage += 1);
      } else if (dragTowardsEndLeft && this.closeToLeftEnd && !this.hasLoadedAllLeft) {
        this.addOffers(this.fetchedMinPage -= 1);
      }
    },
    updateActivePage() {
      if (this.calculatedActivePage !== this.activePage) {
        this.activePage = this.calculatedActivePage;
        this.$emit('updated-page', this.activePage);
      }
    },
    getDraggableParams() {
      return {
        type: 'x',
        edgeResistance: 0.7,
        inertia: true,
        minimumMovement: this.minimumDragMovement,
        zIndexBoost: false,
        onDrag: this.dragHandler,
        onThrowUpdate: this.dragHandler,
      };
    },
    initializeDraggableOn(selector) {
      this.draggableInstance = Draggable
        .create(selector, this.getDraggableParams());
    },
    scrollDraggableToPageCenter(page) {
      this.scrollTo(
        this.calculateCenterOfPage(page) - (this.infiniteShelfWidth / 2),
      );
    },
    scrollTo(position) {
      gsap.set('.infinite-shelf', { x: position * -1 });
      if (this.draggable) this.draggable.update();
    },
    initShelf() {
      this.infiniteShelfWidth = this.$refs.infiniteShelf.clientWidth;
      this.initializeDraggableOn('.infinite-shelf');
      if (this.activePage > 1) {
        this.scrollDraggableToPageCenter(this.activePage);
      }
    },
    initPageData() {
      this.initialPage = this.offerQueryParams.pagination.page;
      this.activePage = this.initialPage;
      this.fetchedMinPage = this.activePage;
      this.fetchedMaxPage = this.activePage;
      this.addOffers(this.activePage);
      if (this.withRebates) {
        this.rebates = Rebates.get();
      }
    },
  },
};
</script>

<style lang="scss">
.infinite-shelf {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: visible;
  position: relative;

  &::before {
    content:'';
    display: block;
    padding-left: var(--boardPadding);
  }
  .tile-page:first-child {
    z-index: 2;
    min-width: calc(var(--shelfWidth) - var(--boardPadding) * 2);
  }

  &__shelf-board-container {
    position: absolute;
    top: 113px;
    left: 0;
    min-width: 100%;
    z-index: -1;
  }

  .shelf-board {
    margin-top: calc(var(--tileHeight) - 115px);
  }
}
</style>
