<template>
  <div
    class="tile-page"
    :style="styleParams"
  >
    <div
      v-if="active"
      class="tile-page__tile-group"
    >
      <div
        v-for="(entry, index) in entries"
        :key="`entry-${index}`"
        class="tile-page__tile"
      >
        <component
          :is="entry.component"
          v-if="entry.component"
          class="tile-page__offer"
          v-bind="entry"
        />
        <div
          v-else
          class="tile-page__tombstone"
        >
          <div class="tombstone__product" />
          <div class="tombstone__product-info">
            <div class="tombstone__product-name" />
            <div class="tombstone__product-price" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    OfferOnShelf: () => import('@/components/offer-on-shelf.vue'),
    UnknownOnShelf: () => import('@/components/unknown-on-shelf.vue'),
    ClayOnShelf: () => import('@/components/clay-on-shelf.vue'),
  },
  props: {
    pageId: { type: Number, required: true },
    active: { type: Boolean, default: false },
    tileWidth: { type: Number, required: true },
    tileHeight: { type: Number, required: true },
    numberOfBoards: { type: Number, required: true },
    itemsOnBoard: { type: Number, required: true },
    entries: { type: Array, default: () => [] },
  },
  computed: {
    styleParams() {
      return `--numberOfBoards: ${this.numberOfBoards};
      --itemsOnBoard: ${this.itemsOnBoard};
      --tileWidth: ${this.tileWidth}px;
      --tileHeight: ${this.tileHeight}px;
    `;
    },
  },
};
</script>

<style lang="scss">
.tile-page {
  $tileMarginBottom: 65px;
  $tileMarginHorizontal: 55px;

  position: relative;
  width: calc(var(--tileWidth) * var(--itemsOnBoard));
  height: 100%;
  flex-shrink: 0;
  top: 20px;

  &__offer {
    padding: 70px 0;
    height: 100%;

    &.clay-on-shelf {
      padding: 10px 0 0;
    }
  }

  &__label {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__tile-group {
    height: calc((var(--tileHeight) + #{$tileMarginBottom}) * var(--numberOfBoards));
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
  }

  &__tile {
    width: calc(var(--tileWidth) - #{$tileMarginHorizontal} * 2);
    height: var(--tileHeight);
    margin: 0 $tileMarginHorizontal $tileMarginBottom;
  }

  &__tombstone {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
  }
}

.tombstone {
  &__product {
    height: 220px;
    width: 140px;
    background-color: #808080;
    margin-left: 36px;
  }

  &__product-info {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

  &__product-name {
    height: 20px;
    width: 240px;
    margin-bottom: 24px;
    background-color: #808080;
  }

  &__product-price {
    height: 20px;
    width: 120px;
    margin-bottom: 24px;
    background-color: #808080;
  }
}
</style>
