<template>
  <div class="screensaver-animated-tiles">
    <div
      v-for="(tiles, index) of getSixTiles"
      :key="`screensaver-tile-container-${index}`"
      ref="tileContainer"
      class="screensaver-animated-tiles__tile-container"
    >
      <div class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--empty-top" />
      <component
        :is="tiles[0].type"
        :key="`screensaver-tile-${index}--front`"
        class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--front"
        :class="{'screensaver-animated-tiles__tile--aktion':tiles[0].aktion}"
        v-bind="tiles[0]"
      />
      <div class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--empty-right" />
      <component
        :is="tiles[1].type"
        :key="`screensaver-tile-${index}--back`"
        :class="[backTileClass,{'screensaver-animated-tiles__tile--aktion':tiles[1].aktion}]"
        class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--back"
        v-bind="tiles[1]"
      />
      <div class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--empty-left" />
      <div class="screensaver-animated-tiles__tile screensaver-animated-tiles__tile--empty-bottom" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import ScreensaverCategoryTile from '@/components/screensaver-category-tile.vue';
import ScreensaverOfferTile from '@/components/screensaver-offer-tile.vue';

// 0.4 seconds before animation from pair Tile will end
const flipBeforeAEndedTime = '-=0.4';
// 2 seconds after animation from switching pair before ended
const flipAfterBTime = '+=2';
const animationDuration = 0.8;
// 180 degree counterClockWise
const rotateVal = '+=180_ccw';
// switching pairs: 5 and 2; 1 and 4; 0 and 3;
const ROTATION_ORDER = [5, 2, 1, 4, 0, 3];

// one iteration is about ~18 seconds
const SHOW_VIDEO_AFTER_ITERATION = 1;

export default {
  name: 'ScreensaverAnimatedTiles',
  components: {
    ScreensaverCategoryTile,
    ScreensaverOfferTile,
  },
  props: {
    tilesData: {
      type: Array,
      required: true,
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
    videoIsPlaying: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeline: null,
      showBlankBackTile: false,
      tileAnimationIteration: 0,
    };
  },
  computed: {
    getSixTiles() {
      return this.tilesData.slice(0, 6);
    },
    backTileClass() {
      return { 'screensaver-animated-tiles__tile--blank-back': this.showBlankBackTile };
    },
  },
  watch: {
    videoIsPlaying: {
      handler(isPlaying, wasPlaying) {
        if (!isPlaying && wasPlaying) {
          this.videoFinished();
        }
      },
    },
  },
  mounted() {
    this.initTileAnimation();
  },
  methods: {
    resetTimeline() {
      this.timeline = gsap.timeline({
        paused: true,
      });
    },
    addTileToTimeline(tileNumber, delay) {
      this.timeline.to(this.$refs.tileContainer[tileNumber], {
        rotationY: rotateVal,
        duration: animationDuration,
      }, delay);
    },
    flipEachTileScene(startTime = 1) {
      // every flipcycle two tiles flip simultanously
      ROTATION_ORDER.forEach((tileNumber, index) => {
        if (index === 0) {
          // firstTile -> startTime
          this.addTileToTimeline(tileNumber, startTime);
        } else if (index % 2 === 1) {
          // odd -> secondTileFlip -> flipBeforeAEndedTime
          this.addTileToTimeline(tileNumber, flipBeforeAEndedTime);
        } else {
          // even -> firstTileFlip -> flipAfterBTime
          this.addTileToTimeline(tileNumber, flipAfterBTime);
        }
      });
    },
    initTileAnimation() {
      this.resetTimeline();
      this.timeline.delay(2);
      this.timeline.eventCallback('onComplete', this.tileAnimationComplete);

      // first flip allTiles to B Side
      this.flipEachTileScene();
      // second flip allTiles to default A Side
      // if not given all tiles will change to its original A side
      // which leads to a not wanted flash at the end of the animation
      this.flipEachTileScene(flipAfterBTime);
      this.timeline.play();
    },
    tileAnimationComplete() {
      this.tileAnimationIteration += 1;
      if (this.hasVideo && this.tileAnimationIteration >= SHOW_VIDEO_AFTER_ITERATION) {
        this.animationToVideo();
        this.tileAnimationIteration = 0;
      } else {
        this.timeline.restart();
      }
    },
    animationToVideo() {
      this.showBlankBackTile = true;
      this.resetTimeline();

      this.timeline.delay(1);
      this.timeline.eventCallback('onComplete', this.animationToVideoComplete);
      this.timeline.eventCallback('onReverseComplete', this.restoreDefaultLayout);

      ROTATION_ORDER.forEach((tileNumber) => {
        this.addTileToTimeline(tileNumber, Math.random() / 2);
      });
      this.timeline.play();
    },
    animationToVideoComplete() {
      this.$emit('show-video');
    },
    videoFinished() {
      this.$emit('hide-video');
      this.timeline.reverse();
    },
    restoreDefaultLayout() {
      this.showBlankBackTile = false;
      this.initTileAnimation();
    },
  },
};
</script>

<style lang="scss">
$sideWidth: 40px;

.screensaver-animated-tiles {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 4096px;
  z-index: 1;
  margin-bottom: 18px;
  margin-top: 26px;

  &__tile-container {
    height: calc((100% - 72px) / 3);
    width: calc(50% - 9px);
    margin-bottom: 18px;
    transform-style: preserve-3d;
    // transform: translateZ(calc(#{$sideWidth} / 2) * - 1);
  }

  &__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 22px;
    cursor: pointer;
    background-color: #2F3234;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
     &--aktion{
    .screensaver-offer-tile__image{
      max-height:none;
      top:initial;
    }
     }

    &--front {
      transform: rotateY(0deg) translateZ(calc(#{$sideWidth} / 2));
    }

    &--empty-right {
      background-color: #282B2D;
      width: $sideWidth;
      transform: rotateY(90deg) translateZ(511px);
    }

    &--back {
      transform: rotateY(180deg) translateZ(calc(#{$sideWidth} / 2));
    }

    &--blank-back {
      > * {
        opacity: 0;
      }
    }

    &--empty-left {
      background-color: #282B2D;
      width: $sideWidth;
      transform: rotateY(270deg) translateZ(calc(#{$sideWidth} / 2));
    }

    &--empty-top {
      background-color: #484B4D;
      height: $sideWidth;
      transform: rotateX(90deg) translateZ(calc(#{$sideWidth} / 2));
    }

    &--empty-bottom {
      background-color: #181818;
      height: $sideWidth;
      transform: rotateX(-90deg) rotateY(-90deg) translateX(calc(100% - 65px)) rotateY(90deg);
    }
  }
}
</style>
