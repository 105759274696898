import axios from 'axios';
import { expandShelfEntry } from '@/lib/shelves-helper';
import fetchCategoryOffers from '@/lib/goliath/categories';

/**
 * @typedef {Object} ProductCategory
 * @property {string | null} categoryNumber
 * @property {number | null} depth
 * @property {string | null} name
 * @property {string | null} path
 * @property {string | null} slug
 */

/**
 * @typedef {Object} ProductCategoryAggregation
 * @property {number | null} offersCount
 * @property {ProductCategory | null} productCategory
 * @property {(ProductCategoryAggregation | null)[] | null} children
 */

/**
 * @typedef {Object} ProductCategories
 * @property {(ProductCategoryAggregation | null)[] | null} entries
 */

/**
 * @typedef {Object} GoliathOffersData
 * @property {ProductCategoryAggregation[] | null} [entries]
 */

/**
 * @typedef {Object} CategoryChild
 * @property {string} slug
 * @property {string} label
 * @property {string} type
 * @property {string} path
 */

/**
 * @typedef {Object} CategoryEntry
 * @property {string | null} name
 * @property {string | null} path
 * @property {string | null} slug
 * @property {CategoryChild[]} children
 */

/**
 * Fetches and processes Goliath child categories.
 *
 * @returns {Promise<CategoryEntry[]>} - A promise that resolves to a list of category entries.
 */
async function getGoliathChildCategories() {
  const goliathOffersData = await fetchCategoryOffers({
    filter: {
      withAvailableProducts: true,
    },
  });

  return goliathOffersData.entries.map((entry) => ({
    name: entry.productCategory.name,
    path: entry.productCategory.path,
    slug: entry.productCategory.slug,
    children: entry.children.map(
      (child) => ({
        slug: child.productCategory.slug,
        label: child.productCategory.name,
        type: 'category',
        path: child.productCategory.path,
      }),
    ),
  }));
}

const substituteEntry = (shelfContents, entry) => {
  const mapping = shelfContents?.data?.mapEntries?.find(({ slug }) => slug === entry.slug);
  return {
    ...entry,
    ...mapping,
  };
};

/**
 *
 * @param shelfContents
 * @param {CategoryEntry[]} goliathCategories
 * @returns {{}}
 */
function getShelfContentTree(shelfContents, goliathCategories) {
  // Filter some categories
  const filteredCategories = goliathCategories.filter((entry) => !['deindesign'].includes(entry.slug));

  const knownTopCategories = filteredCategories.map((entry) => entry.slug).filter((slug) => !['smartphones-und-handys', 'tablets'].includes(slug));
  const combinedCategories = {
    start: {
      ...shelfContents.data.start,
      items: shelfContents.data.start?.items?.map((item) => expandShelfEntry(substituteEntry(shelfContents, item), {
        knownTopCategories,
      })),
    },
  };

  filteredCategories.forEach((category) => {
    // Tablets are special. We want to show only the Main Category!
    if (['tablets'].includes(category.slug)) {
      combinedCategories[category.slug] = substituteEntry(shelfContents, {
        label: category.name,
        copy: 'Wonach suchen Sie?',
        slug: category.slug,
        link: `/categories/${category.slug}`,
      });

      return;
    }

    if (category.children.length === 1) {
      combinedCategories[category.slug] = substituteEntry(shelfContents, {
        label: category.children[0].label,
        copy: 'Wonach suchen Sie?',
        slug: category.slug,
        link: `/categories/${category.path}`,
      });

      return;
    }

    const context = {
      slugPrefix: `${category.slug}/`,
      knownTopCategories,
    };
    combinedCategories[category.slug] = substituteEntry(shelfContents, {
      label: category.name,
      copy: 'Wonach suchen Sie?',
      slugPrefix: `${category.slug}/`,
      slug: category.slug,
      items: (category.children || []).map((item) => expandShelfEntry(substituteEntry(shelfContents, item), context)),
      link: `/sub-categories/${category.slug}`,
    });
  });

  return combinedCategories;
}

export default {
  namespaced: true,
  state() {
    return {
      initialized: false,
      categories: undefined,
      offersItem: undefined,
      noveltyItem: undefined,
    };
  },
  actions: {
    async loadFromBackend({ commit }) {
      try {
        const response = await axios.get('/content/content-json/virtual-shelf/shelf-contents.json');
        const goliathChildCategories = await getGoliathChildCategories();
        const shelfContentTree = getShelfContentTree(response, goliathChildCategories);
        commit('SET_CATEGORIES', shelfContentTree);
      } catch (exception) {
        console.error('FAILED TO LOAD SHELF CONTENT', exception);
        throw exception;
      }
    },
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
      state.offersItem = state.categories.start?.items?.find((item) => item.label === 'Angebote');
      state.noveltyItem = state.categories.start?.items?.find((item) => item.label === 'Neuheiten');
      state.initialized = true;
    },
  },
};
