<template>
  <div>
    <BulletPointList
      v-if="displayOfferUsps"
      :list="filterHighlightItems(offer.usps)"
      itemprop="description"
    />

    <ProductContentDetails
      v-if="displayProductUsps"
      :product="product"
      :render-item-if="recordHasUsps"
    >
      <BulletPointList
        slot-scope="props"
        :is-bundle="product.bundle"
        :list="filterHighlightItems(props.product.usps)"
        itemprop="description"
      />
    </ProductContentDetails>

    <BulletPointList
      v-if="displayContractUsps"
      :list="filterHighlightItems(offer.contract.usps)"
      itemprop="description"
    />
  </div>
</template>

<script>
import {
  offerHasContractUsps,
  offerHasOwnUsps,
  offerHasProductUsps,
  recordHasUsps,
} from '@/lib/goliath/offer-usps';
import ProductContentDetails from '@/components/product-detail/product-content-details.vue';
import BulletPointList from '@/components/bullet-point-list.vue';

export default {
  name: 'OfferHighlights',
  components: {
    BulletPointList,
    ProductContentDetails,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayOfferUsps() {
      return offerHasOwnUsps(this.offer);
    },
    displayProductUsps() {
      return !this.displayOfferUsps && offerHasProductUsps(this.offer);
    },
    displayContractUsps() {
      return !this.displayOfferUsps
        && !this.displayProductUsps
        && offerHasContractUsps(this.offer);
    },
  },
  methods: {
    recordHasUsps,
    filterHighlightItems(data) {
      return data.map((i) => ({ text: i }));
    },
  },
};
</script>

<style lang="scss">

</style>
