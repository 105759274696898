<template>
  <div class="teufel">
    <Header />
    <div class="teufel__container">
      <TeufelStart />
      <TeufelHistory />
      <TeufelCategoryShelf />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { mapMutations } from 'vuex';
import actions from '@/store/actions';
import TeufelStart from './teufel-start.vue';
import TeufelHistory from './teufel-history.vue';
import TeufelCategoryShelf from './teufel-category-shelf.vue';

export default {
  name: 'Teufel',
  components: {
    Header,
    Footer,
    TeufelStart,
    TeufelHistory,
    TeufelCategoryShelf,
  },
  mounted() {
    this.$store.dispatch(actions.UPDATE_BREADCRUMB, [
      { path: '/start', label: 'Home' },
      { path: '/teufel', label: 'Teufel' },
    ]);
  },
  methods: {
    ...mapMutations({
      updateBreadcrumb: actions.UPDATE_BREADCRUMB,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.teufel {
  height: 100%;

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    touch-action: pan-x;
    scroll-snap-type: x mandatory;

    > div {
      flex-shrink: 0;
      height: 1920px;
      scroll-snap-stop: normal;
      scroll-snap-align: start;
    }
  }
}
</style>
