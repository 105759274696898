<template>
  <div
    class="cart-list-item"
    :class="{ 'cart-list-item--bundle-product' : isBundleProduct }"
  >
    <router-link
      :to="itemPagePath"
      class="cart-list-item__link"
    >
      <GoliathImage
        v-if="!isBundleProduct"
        :path="imagePath"
        :alt="name"
        class="cart-list-item__image"
        onerror="this.src='/content/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png'"
      />
      <span class="cart-list-item__title">
        <span v-if="isBundleItem">{{ quantity }}x</span>
        {{ name }}
      </span>
    </router-link>
    <div
      v-if="!isBundleItem"
      class="cart-list-item__count-control"
    >
      <button
        class="cart-list-item__count-button"
        @click="removeItem"
      >
        <MinusIcon class="cart-list-item__count-icon" />
      </button>
      <span class="cart-list-item__count">{{ quantity }}</span>
      <button
        class="cart-list-item__count-button"
        @click="addItem"
      >
        <PlusIcon class="cart-list-item__count-icon" />
      </button>
    </div>
    <span
      v-if="!isBundleItem"
      class="cart-list-item__price"
    >
      <Price
        :amount="price"
        currency="EUR"
        variant="unstyled"
      />
    </span>
  </div>
</template>

<script>
import PlusIcon from '@/assets/images/plus-icon.svg';
import MinusIcon from '@/assets/images/minus-icon.svg';
import actions from '@/store/actions';
import Price from '@/components/price.vue';
import GoliathImage from '@/components/goliath-image.vue';

export default {
  name: 'CartListItem',
  components: {
    GoliathImage,
    PlusIcon,
    MinusIcon,
    Price,
  },
  props: {
    articleNumber: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    isBundleProduct: {
      type: Boolean,
      default: false,
    },
    isBundleItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemPagePath() {
      return `/offer/${this.slug}`;
    },
    imagePath() {
      return this.img || '/content/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png';
    },
  },
  methods: {
    addItem() {
      this.$store.dispatch(actions.ADD_TO_CART, {
        articleNumber: this.articleNumber,
      });
    },
    removeItem() {
      this.$store.dispatch(actions.ADD_TO_CART, {
        articleNumber: this.articleNumber,
        quantity: -1,
      });
    },
  },
};
</script>

<style lang="scss">
.cart-list-item {
  height: 110px;
  display: flex;
  align-items: center;
  font-size: 30px;
  vertical-align: baseline;
  margin-bottom: 20px;

  &--bundle-product {
    height: auto;
  }

  &__link {
    text-decoration: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    vertical-align: baseline;

    &:visited {
      text-decoration: inherit;
      color: inherit;
    }
  }

  &__title {
    margin-left: 20px;
    margin-right: 35px;
    width: 551px;
    line-height: 40px;
    .cart-list-item--bundle-product & {
      width: 551px + 110px + 20px;
      margin-left: 0;
    }
  }

  &__count-control {
    font-size: 30px;
    width: 147px;
    line-height: 40px;
    display: flex;
    margin-right: 38px;
  }

  &__count {
    flex-grow: 1;
    line-height: 52px;
    text-align: center;
  }

  &__count-button {
    all: unset;
    height: 48px;
    width: 48px;
    border-radius: 6px;
    border: 2px solid #000000;
    border-radius: 6px;
    display: flex;
  }

  &__count-icon {
    margin: auto;
  }

  &__price {
    text-align: right;
    width: 110px;
  }

  &__image {
    height: 110px;
    width: auto;
  }
}
</style>
