<template>
  <div class="cart-summary ">
    <div class="cart-summary__price-section">
      <Typography variant="title-magenta">
        Gesamtsumme:
      </Typography>
      <Typography
        variant="display-magenta"
        class="cart-summary__price-text"
      >
        <Price
          :amount="total"
          currency="EUR"
        />
      </Typography>
    </div>
    <div class="cart-summary__continue-section">
      <router-link to="/start">
        <CommonButton variant="secondary">
          Weiter einkaufen
        </CommonButton>
      </router-link>
    </div>
    <div class="cart-summary__checkout-section">
      <router-link :to="checkoutLink">
        <CommonButton
          ref="checkout-button"
          :disabled="!isCheckoutAllowed"
        >
          Zur Bestellung
        </CommonButton>
      </router-link>
      <Typography
        variant="hint"
        class="cart-summary__hint-text"
      >
        Kostenlose Lieferung, 1-6 Werktage<br>
        Versand durch Brodos AG
      </Typography>
    </div>
  </div>
</template>

<script>
import CommonButton from '@/components/common-button.vue';
import Typography from '@/components/typography.vue';
import Price from '@/components/price.vue';

export default {
  name: 'CartSummary',
  components: { CommonButton, Typography, Price },
  props: {
    isCheckoutAllowed: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    checkoutLink() { return this.isCheckoutAllowed ? '/cart/select-payment' : ''; },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.cart-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__price-section {
    grid-column: 2;
    text-align: right;
  }

  &__continue-section {
    margin-top: 50px;
    grid-column: 1;
    grid-row: 2;
  }

  &__checkout-section {
    margin-top: 50px;
    justify-self: right;
    text-align: right;
    grid-column: 2;
    grid-row: 2;
  }

  &__price-text {
    text-align: right;
  }

  &__hint-text {
    @include font-size(18px, 24px);
    padding-top: 18px;
    text-align: right;
  }
}
</style>
